import React, { useState, useEffect } from 'react';
import axios from './../utils/axiosConfig';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom'; 
import './QuizList.css';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';

const QuizList = () => {
  const [allQuizzes, setAllQuizzes] = useState([]);
  const [displayedQuizzes, setDisplayedQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(null); // Pour gérer les erreurs
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await axios.get('/quizz',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        setAllQuizzes(response.data);
        setDisplayedQuizzes(response.data); // Afficher tous les quizz
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
        setError("Une erreur s'est produite lors de la récupération des quizz.");
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, []);

  const handleQuizClick = (quiz) => {
    setSelectedQuiz(quiz);
    setShowConfirmation(true);
  };

  const handleStartQuiz = () => {
    setShowConfirmation(false);
    navigate(`/quiz/${selectedQuiz.id}`);
  };

  return (
    <>
    <NavBar />
    <div id="main" style={{ height: '100%' }}>
    <div className="quiz-list-container apropos" style={{marginTop:'8rem'}}>
      <h3 className="quiz-list-title" style={{marginTop:'3rem', marginBottom:'3rem', fontWeight:'bold', color:'#ffa500'}}>Évaluation du bien-être</h3>
      {loading ? (
        <div className="spinner-container">
          <ProgressSpinner />
        </div>
      ) : error ? (
        <div className="error-message">
          <p>{error}</p>
        </div>
      ) : (
        <>
          {showConfirmation ? (
            <div className="confirmation-container">
              <p className="confirmation-message" style={{fontWeight:'bold'}}>Bienvenue au quizz santé. Êtes-vous prêt à commencer ?</p>
              <Button
                label="Démarrer"
                className="start-quiz-button"
                onClick={handleStartQuiz}
              />
              <Button
                label="Annuler"
                className="cancel-button"
                onClick={() => setShowConfirmation(false)}
              />
            </div>
          ) : displayedQuizzes.length > 0 ? (
            displayedQuizzes.map((quiz) => (
              <div key={quiz.id} className="quiz-button-container">
                <Button
                  label={quiz.name}
                  className="quiz-button"
                  onClick={() => handleQuizClick(quiz)}
                />
              </div>
            ))
          ) : (
            <p>Aucun quiz disponible pour le moment.</p>
          )}
        </>
      )}
      </div>
      <Footer />
    </div>
    
    </>
  );
};

export default QuizList;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';
import axios from '../utils/axiosConfig';
import ImageCarousel from './ImageCarousel';
import ChocolateCardFactChecking from './ChocolateCardFactChecking';
import ChocolateCardInfectious from './ChocolateCardInfectious';
import ChocolateCardMaternal from './ChocolateCardMaternal';
import ChocolateCardMental from './ChocolateCardMental';
import ChocolateCardReproductive from './ChocolateCardReproductive';
import ChocolateCardHealthLaw from './ChocolateCardHealthLaw';
import ChocolateCardHealthNews from './ChocolateCardHealthNews';
import { Carousel } from 'primereact/carousel';  // Importez le composant Carousel de PrimeReact

const Home = () => {
    const [sanitaryInfos, setSanitaryInfos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rdv, setRdvs] = useState([]);

    useEffect(() => {
        const visitorTracked = localStorage.getItem('visitorTracked');
        const getInf = async () => {
            if (!visitorTracked) {
                try {
                    await axios.post('/api/visitor-add');
                    localStorage.setItem('visitorTracked', 'true'); // Mark this visitor as tracked
                } catch (error) {
                    console.error('Error updating visitor count', error);
                }
            }

            try {
                const response = await axios.get('/informations_sanitaires');
                setSanitaryInfos(response.data.slice(0, 3)); // Only the last 3 publications
                setLoading(false);
            } catch (error) {
                setError('Erreur lors de la récupération des informations sanitaires');
                setLoading(false);
            }

            // Fetch RDVs 
            try {
                const response = await axios.get("/rendezvous-stats");
                setRdvs(response.data);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        getInf();
    }, []);


    const renderSanitaryInfo = (sanitaryInf) => {
        const categoryNom = sanitaryInf.Category.nom.toLowerCase();
                
        if (categoryNom.includes('mentale')) {
            return <ChocolateCardMental key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('maternelle') || categoryNom.includes('infantile')) {
            return <ChocolateCardMaternal key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('sexuelle') || categoryNom.includes('reproductive')) {
            return <ChocolateCardReproductive key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('infectieuse')) {
            return <ChocolateCardInfectious key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('factchecking')) {
            return <ChocolateCardFactChecking key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('droit')) {
            return <ChocolateCardHealthLaw key={sanitaryInf.id} article={sanitaryInf} />;
        } else if (categoryNom.includes('actualités') || categoryNom.includes('actu')) {
            return <ChocolateCardHealthNews key={sanitaryInf.id} article={sanitaryInf} />;
        }
        return null; // Fallback for any other cases
    };

    const itemTemplate = (sanitaryInf) => {
        return renderSanitaryInfo(sanitaryInf);
    };

    return (
        <>
            <NavBar />
            <div id="main" style={{ height: '100%' }}>
                <div className="pt-0 pb-5" style={{ marginTop: '8rem' }}>
                    <div className="w-100" style={{ margin: '0 auto' }}>
                        <ImageCarousel />
                    </div>
    
                    <div className="container pt-0 pb-5">
                        <div className="mt-5 w-100" style={{ margin: "0 auto", fontWeight: 'bold' }}>
                            <h4 style={{ fontWeight: 'bold', color: '#ffa500' }}>Les dernières parutions</h4>
                        </div>
                        <div className="w-100" style={{ margin: '0 auto' }}>
                            {loading ? (
                                <p>Chargement...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : sanitaryInfos.length > 0 ? (
                                <Carousel
                                    value={sanitaryInfos}
                                    itemTemplate={itemTemplate}
                                    numVisible={3} // Affichage de 3 éléments par défaut
                                    numScroll={2} // Définit combien d'éléments défilent à la fois
                                    circular
                                    autoplay={true}
                                    autoplayInterval={3000}
                                    transitionInterval={500}
                                    responsiveOptions={[
                                        {
                                            breakpoint: '990px', // Pour les écrans entre 700px et 990px
                                            numVisible: 2,
                                            numScroll: 1,
                                        },
                                        {
                                            breakpoint: '700px', // Pour les écrans de moins de 700px
                                            numVisible: 1,
                                            numScroll: 1,
                                        },
                                        {
                                            breakpoint: '480px', // Pour les très petits écrans comme les téléphones
                                            numVisible: 1,
                                            numScroll: 1,
                                        },
                                    ]}
                                    style={{
                                        width: '100%', // S'assure que le carrousel prend toute la largeur du parent
                                        padding: '0', // Enlever tout padding supplémentaire
                                        boxSizing: 'border-box', // Garantir que les paddings sont gérés correctement
                                    }}
                                    className="custom-carousel"
                                />
                            ) : (
                                <p>Pas d'information pour le moment</p>
                            )}

                            <div className="col-12 pt-3">
                                <Link
                                    to="/healthInfo"
                                    className="btn btn-lg btn-block btn-secondary rounded-1 btn-text-transform-1 text-light px-1 fs-8"
                                    title="Voir plus d'informations sanitaires"
                                >
                                    Voir plus
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* FAQ Section */}
                    <div className="pt-0 pb-5">
                        <div className="col-button w-100 bg-dark text-light px-4 px-lg-0 pt-5 pb-5 text-center" style={{ margin: "0 auto" }}>
                            <div className="h3 mt-5">Questions fréquemment posées</div>
                            <h6 className="fw-lighter mb-5" style={{ maxWidth: "580px", marginLeft: "auto", marginRight: "auto" }}>
                                <p>Vous-vous demandez ce qui vous arrive lorsque vous avez tel ou tel symptôme ? Ou bien comment faire face à un signe annonciateur donné ?</p>
                            </h6>
                            <Link to="/faq" className="btn btn-lg mb-5 btn-secondary rounded-1 btn-text-transform-1 text-light" style={{ backgroundColor: "#269e4a" }}>
                                Découvrir nos questions-réponses
                            </Link>
                        </div>
                    </div>

                    {/* Video Section */}
                    <div className="container pt-0 pb-3">
                        <div className="w-100" style={{ margin: "0 auto" }}>
                            <video controls className="w-100 video-js vjs-16-9 vjs-default-skin rounded"
                                poster="../app.creer-application.com/data/applications/pictures/11090/poster/e0a17ee1976a3c3ab5b126b1ea2b3641.jpg"
                                data-setup='{"controls": true, "autoplay": "false", "preload": "false", "controlBar": { "pictureInPictureToggle": false } }'>
                                <source src="/assets/videos/presentation-yoheda.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    {/* Card Section */}
                    <div className="container text-center card pt-4 pb-3 mt-3 mb-4"
                        style={{ position: 'relative', height: '20rem', overflow: 'hidden', backgroundImage: 'url("/data/applications/pictures/rendez-vous.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="w-100">
                            <h3 className="text-light" style={{ marginTop: '6rem', fontWeight: 'bold', color: '#eed' }}>
                                Échanger avec un personnel de santé
                            </h3>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        </>
    );
};

export default Home;

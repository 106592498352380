import React from "react";
import { Link } from "react-router-dom";
import RatingModal from "./feedback";


const Footer = () => {
  return (
    <>
      <div className="py-4 px-4 pt-5 px-xl-5 py-xl-4 pt-xl-5 bg-dark text-light" id="footer">
        <div className="container">
          <div className="row g-lg-5">
            <div className="col-lg-3">
              <img
                src="/data/applications/logos/11090/hd/4b544732833a213a2d21f318f490b2e0.png"
                alt="Conseils de Plantes"
                className="mb-4 mb-lg-4 img-fluid logo"
              />
              <p className="mb-4 mb-lg-5">L'information sur votre Santé proche de vous.</p>
            </div>
            <div className="col-lg-5">
              <div className="ms-lg-5 mb-lg-5">
                <div className="h3 mb-2 mb-lg-4 text-light">Navigation</div>
                {/* Utilisation de d-grid et gap pour créer deux colonnes */}
                <ul className="mb-4 mb-lg-0 d-grid gap-2" style={{ gridTemplateColumns: "1fr 1fr" }}>
                  <li >
                    <Link className="text-ligh" to="/" data-ca-link-id="home"  >
                      Accueil
                    </Link>
                  </li>
                  <li >
                    <Link className="text-light" to="/healthInfo" data-ca-link-id="healInfo"  >
                      Informations Sanitaires
                    </Link>
                  </li>
                  <li >
                    <Link className="text-light" to="/assistance" data-ca-link-id="assistance" >
                      Assistance
                    </Link>
                  </li>
                  <li >
                    <Link className="text-light"  to="/faq" data-ca-link-id="faq" >
                      FAQ
                    </Link>
                  </li>
                  <li >
                    <Link  className="text-light" to='/quizzList' data-ca-link-id="quizzList"  >
                      Bien-être
                    </Link>
                  </li>
                  <li >
                    <Link className="text-light" to="/about" data-ca-link-id="about"  >
                      À propos
                    </Link>
                  </li>
                  <li>
                    <Link t to='/contact' data-ca-link-id="contact" className="text-light">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/politique-de-confidentialite" className="text-light">
                      <span>Politique de confidentialité</span>
                    </Link>
                 </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="h3 mb-2 mb-lg-4 text-light">Informations</div>
              <address> YOHEDACARE<br /><br /><br /></address>
            </div>
          </div>
          <hr />
        </div>

        <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="mb-3 mb-md-0">
            Retrouvez-nous sur <Link href="#" to="https://yohedahealth.org/" target="_blank">https://yohedahealth.org/</Link>
          </div>
          <div>
            <Link to="https://www.facebook.com/Yoheda/" className="me-2 p-2 text-light" target="_blank">
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link to="https://x.com/yohedahealth/" className="me-2 p-2 text-light" target="_blank">
              <i className="fab fa-twitter"></i>
            </Link>
            <Link to="https://www.linkedin.com/" className="me-2 p-2 text-light" target="_blank">
              <i className="fab fa-linkedin-in"></i>
            </Link>
            <Link to="https://www.tiktok.com/@yoheda?is_from_webapp=1&sender_device=pc/" className="me-2 p-2 text-light" target="_blank">
              <i className="fab fa-tiktok"></i>
            </Link>
            <Link to="https://www.instagram.com/yohedahealth/" className="me-2 p-2 text-light" target="_blank">
              <i className="fab fa-instagram"></i>
            </Link>
          </div>
          <RatingModal />
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import NavBar from "../partials/nav";
import { Toast } from "primereact/toast";
import axios, { baseURL, frontendUrl, wsBackendUrl } from "../utils/axiosConfig"; // wsBackendUrl pour WebSocket
import { useParams } from "react-router-dom";
import { Menu } from "primereact/menu";
import { FaCheckDouble } from 'react-icons/fa';
import { Chip } from 'primereact/chip';

import { Galleria } from 'primereact/galleria';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { ContextMenu } from 'primereact/contextmenu';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputTextarea } from "primereact/inputtextarea";








const ChatPage = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [message, setMessage] = useState("");
    const [discussions, setDiscussions] = useState([]);
    const [messagesByChat, setMessagesByChat] = useState({});
    const [unReadCount, setUnReadCount] = useState({})
    const { id } = useParams();
    
    const toast = useRef(null);
    const ws = useRef(null);
    const imageInputRef = useRef(null);
    const videoInputRef = useRef(null);
    const documentRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
    const [selectedMessage, setSelectedChatMessage] = useState(null);
    const [connected, setConnected] = useState(true);
    const lastMessageRef = useRef(null); 
    const MediaCarousel = ({ messages,  ind }) => {
        const [activeIndex, setActiveIndex] = useState(ind || 0);
        const responsiveOptions = [
            {
                breakpoint: '991px',
                numVisible: 4
            },
            {
                breakpoint: '767px',
                numVisible: 3
            },
            {
                breakpoint: '575px',
                numVisible: 1
            }
        ];
        const thumbnailTemplate = (item) => {
            return item ? <img src={baseURL +item.src} alt={"Image de " + item.Utilisateur.nom} width={20} />: ""
        }
        const itemTemplate = (item) => {
            return item ? <img src={baseURL +item?.src} alt={"Image de " + item.Utilisateur.nom} style={{ width: '700px', height:"700px" }} />:""
        }
        const items = messages.filter((msg)=>msg.type !== "text" && msg.type !== "document"); // Filtrer les messages invalides
    
        const onChange = (e) => {
            setActiveIndex(e.value);
        };
    
        return (
            <div>
                    <Galleria value={items} activeIndex={activeIndex} responsiveOptions={responsiveOptions} numVisible={5}  
                        item={itemTemplate} thumbnail={thumbnailTemplate} />             
               
            </div>
        );
    };

    const messageMenu = useRef(null);

    const menu = useRef(null);  // Référence pour le menu
    // Menu pour les autres types de messages (image, audio, vidéo)
    const menuItems = [
        {
            label: ' image',
            icon: 'pi pi-image',
            command: () => { imageInputRef.current.click(); }
        },
        {
            label: 'audio',
            icon: 'pi pi-volume-up',
            command: () => { videoInputRef.current.click(); }
        },
        {
            label: 'vidéo',
            icon: 'pi pi-video',
            command: () => { documentRef.current.click(); }
        },
        {
            label : 'document',
            icon : 'pi pi-file',
            command : ()=> { handleSendMessage ('document')}
        }
    ];

    const messageMenuItems = [
        {
            label: ' Informations',
            icon: 'pi pi-image',
            command: () => { imageInputRef.current.click(); }
        },
        {
            label: 'Modifier',
            icon: 'pi pi-volume-up',
            command: () => { videoInputRef.current.click(); }
        },
        {
            label: 'Supprimer',
            icon: 'pi pi-video',
            command: () => { documentRef.current.click(); }
        }
    ];
    const user_informations = JSON.parse(localStorage.getItem('user_informations'));




// Fonction pour envoyer un fichier via WebSocket en chunks
const uploadFileViaWebSocket = (file) => {

    // Détection du type de fichier (image, vidéo, document)
    let fileType;
    if (file.type.startsWith('image/')) {
        fileType = 'image';
    } else if (file.type.startsWith('video/')) {
        fileType = 'video';
    } else {
        fileType = 'document'; // Par défaut, considérer le fichier comme un document
    }
    console.log(fileType);


        const formFile = new FormData();
        formFile.append("file", file);
        const token = localStorage.getItem("token");
        axios.post("/file", formFile,  {
            header:{
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data",
        }})
        .then((res)=>{

            const newMessage = {
                contenu: message,
                UtilisateurId: user_informations.id,
                ChatRoomId: selectedChat.id,
                type:fileType,
                src:res.data.url
            };

            // Envoi du message via WebSocket
            ws.current?.send(JSON.stringify({ type: "send_message", message: newMessage }));
        })
        .catch((err)=>{
            
            toast.current?.show({ sumary:"Oups !", severity:"warning", detail:err.message ? err.message : "Quelque chose a mal tourné pendant l'envoie du fichier", live:3000});
            console.warn(err);
            return null;
        });
};

// Fonction d'upload générique pour tous types de fichiers
const onUploadFile = (event) => {
    setUploading(true);
    const files = event.target.files; // Liste des fichiers uploadés
    
    for (let i = 0; i < files.length; i++) {
        uploadFileViaWebSocket(files[i]);
    }
    setMessage("");
    setUploading(false);
};


// recuperer et telecharger le media actuellement selectionné
const handleDownloadMedia = (e) => {
    e.preventDefault();
    if (selectedMediaIndex !== null) {
        const selectedMessage = messagesByChat[selectedChat?.id][selectedMediaIndex];
        if (selectedMessage) {
            const link = document.createElement('a');
            link.href = baseURL + selectedMessage.src;
            link.target = "download";
            link.download = selectedMessage.src.split('/').pop();
            link.click();
        }
    }
};


    const handleAudio = (e) =>{
        e.preventDefault();
        toast.current?.show({
            severity: "warn",
            summary: "Oups !",
            detail: "Pas encore implementé..",
        });
    }
 // Fonction de connexion WebSocket
//  const connectWebSocket = () => {
//     ws.current = new WebSocket(wsBackendUrl);

//     ws.current.onopen = () => {
//         reconnectInterval.current = 1000; // Réinitialiser l'intervalle de reconnexion
//         ws.current.send(JSON.stringify({ type: "identification", UtilisateurId: user_informations.id }));
//         console.log("WebSocket connected");
//     };

//     ws.current.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         if (data.type === "new_message") {
//             setMessagesByChat((prevMessages) => {
//                 const chatMessages = prevMessages[data.message.ChatRoomId] || [];
//                 return {
//                     ...prevMessages,
//                     [data.message.ChatRoomId]: [...chatMessages, data.message],
//                 };
//             });
//             if (selectedChat?.id === data.message.ChatRoomId){
//                 ws.current.send(JSON.stringify({ type: "read_messages", UtilisateurId: user_informations.id, ChatRoomId: selectedChat.id }));
//             } else {
//                 setUnReadCount((prevUnreadCount) => ({
//                     ...prevUnreadCount,
//                     [data.message.ChatRoomId]: (prevUnreadCount[data.message.ChatRoomId] || 0) + 1
//                 }));
//             }
//         } else if (data.type === "reply") {
//             setMessagesByChat((prevMessages) => {
//                 const chatMessages = prevMessages[data.message.ChatRoomId] || [];
//                 return {
//                     ...prevMessages,
//                     [data.message.ChatRoomId]: [...chatMessages, data.message],
//                 };
//             });
//         } else if (data.type === 'read_messages') {
//             const { ChatRoomId } = data;
//             setMessagesByChat((prevMessages) => {
//                 const chatMessages = prevMessages[ChatRoomId] || [];
//                 return {
//                     ...prevMessages,
//                     [ChatRoomId]: chatMessages.map((message) =>
//                         message.UtilisateurId === user_informations.id ? { ...message, lu: true } : message
//                     ),
//                 };
//             });
//         }
//     };

//     ws.current.onclose = () => {
//         console.log("WebSocket connection closed, attempting to reconnect...");
//         attemptReconnect();
//     };

//     ws.current.onerror = (error) => {
//         console.error("WebSocket error:", error);
//         attemptReconnect();
//     };
// };

// Fonction de tentative de reconnexion
// const attemptReconnect = () => {
//     setTimeout(() => {
//         reconnectInterval.current = Math.min(reconnectInterval.current * 2, maxReconnectInterval); // Doubler l'intervalle jusqu'à 30s
//         connectWebSocket(); // Retenter la connexion
//     }, reconnectInterval.current);
// };

    const token = localStorage.getItem("token");
    const fetchDiscussions = async () => {
        try {
            const response = await axios.get("/chatrooms", {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            });
            setDiscussions(response.data);
            if (response.data.find((d) => d.id === id)) {
                setSelectedChat(response.data.find((d) => d.id === id));
            }
        } catch (err) {
            console.error("Erreur lors du chargement des discussions", err);
        }
    };

    const fetchMessages = async () => {
        try {
            const response = await axios.get("/messages", {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            });
            let unreaded = {};
            const groupedMessages = response.data.reduce((acc, message) => {
                unreaded[message.ChatRoomId] = unreaded[message.ChatRoomId] || 0;
                acc[message.ChatRoomId] = acc[message.ChatRoomId] || [];
                acc[message.ChatRoomId].push(message);
                if (!message.lu && message.UtilisateurId !== user_informations.id) {
                    unreaded[message.ChatRoomId] += 1;
                }
                return acc;
            }, {});
            setMessagesByChat(groupedMessages);
            setUnReadCount(unreaded);
        } catch (err) {
            console.error("Erreur lors du chargement des messages", err);
        }
    };

const [serviceWorker, setServiceWorker] = useState(null);


useEffect(() => {
    if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
}, [messagesByChat[selectedChat?.id]]); // Déclenche le défilement lorsque les messages changent


useEffect(()=>{
    navigator.serviceWorker.ready.then((registration) => {
        console.log("Service Worker prêt :", registration.active);
        registration.active.postMessage({
            type: 'ping',
        });
    }).catch((err) => console.error("Erreur avec le Service Worker :", err));
    
}, [selectedChat]);
// Modifiez l'useEffect pour initialiser la communication avec le Service Worker
useEffect(() => {
    // Initialiser la communication avec le Service Worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker?.ready?.then(registration => {
            setServiceWorker(registration.active);
            
            // Envoyer la configuration WebSocket au Service Worker
            registration.active.postMessage({
                type: 'initiate_ws',
                data: {
                    url: wsBackendUrl,
                    id: user_informations.id
                }
            });
            
            // Écouter les messages du Service Worker
            navigator.serviceWorker.addEventListener('message', (event) => {
                const { type, data, status } = event.data;
                console.log("Message recu du service worker", event.data)
                
                if (type === 'ws_message') {
                    const wsData = data;
                    
                    if (wsData.type === "new_message") {
                        setMessagesByChat((prevMessages) => {
                            const chatMessages = prevMessages[wsData.message.ChatRoomId] || [];
                            
                            // Vérifier si le message existe déjà dans le tableau des messages
                            const messageExists = chatMessages.some(msg => msg.id === wsData.message.id);
                            
                            // Si le message n'existe pas déjà, l'ajouter à la liste
                            if (!messageExists) {
                                return {
                                    ...prevMessages,
                                    [wsData.message.ChatRoomId]: [...chatMessages, wsData.message],
                                };
                            }
                        
                            // Si le message existe déjà, ne rien faire (ne pas ajouter)
                            return prevMessages;
                        });
                        
                        if (selectedChat?.id === wsData.message.ChatRoomId) {
                            serviceWorker.postMessage({
                                type: 'read_messages',
                                data: {
                                    UtilisateurId: user_informations.id,
                                    ChatRoomId: selectedChat.id
                                }
                            });
                        } else {
                            setUnReadCount((prevUnreadCount) => ({
                                ...prevUnreadCount,
                                [wsData.message.ChatRoomId]: (prevUnreadCount[wsData.message.ChatRoomId] || 0) + 1
                            }));
                        }
                        if (Notification.permission === "granted") {
                            console.log(wsData.message);
                            
                            const notification = new Notification(`${wsData.message.Utilisateur?.nom} ${wsData.message.Utilisateur?.prenom}`, {
                                body: wsData.message.contenu,
                                icon: `${baseURL}${wsData.message.Utilisateur?.photo}`, // Icône facultative
                            });
                            // Ajouter un son à la notification
                            const notificationSound = new Audio(`${frontendUrl}/data/applications/audio/mixkit-bell-notification-933.mp3`); // Chemin du fichier audio
                            notificationSound.play()
                            notification.onclick = function(event) {
                                event.preventDefault(); // Empêche le comportement par défaut
                                window.focus(); 
                                window.open(`${frontendUrl}/discussion/${wsData.message.ChatRoomId}`, '_self'); 
                                // faire que la fenêtre soit affichée à l'utilisateur
                            };
                        }
                    } else if (wsData.type === "reply") {
                        setMessagesByChat((prevMessages) => {
                            const chatMessages = prevMessages[wsData.message.ChatRoomId] || [];
                            
                            // Vérifier si le message existe déjà dans le tableau des messages
                            const messageExists = chatMessages.some(msg => msg.id === wsData.message.id);
                            
                            // Si le message n'existe pas déjà, l'ajouter à la liste
                            if (!messageExists) {
                                return {
                                    ...prevMessages,
                                    [wsData.message.ChatRoomId]: [...chatMessages, wsData.message],
                                };
                            }
                        
                            // Si le message existe déjà, ne rien faire (ne pas ajouter)
                            return prevMessages;
                        });
                        
                    } else if (wsData.type === 'read_messages') {
                        const { ChatRoomId } = wsData;
                        setMessagesByChat((prevMessages) => {
                            const chatMessages = prevMessages[ChatRoomId] || [];
                            return {
                                ...prevMessages,
                                [ChatRoomId]: chatMessages.map((message) =>
                                    message.UtilisateurId === user_informations.id ? { ...message, lu: true } : message
                                ),
                            };
                        });
                    }
                }else if (type === 'ws_status'){
                    if(status) {
                        setConnected(status === 'connected');
                    }else{
                        console.error("Mal formatted message of service worker", event.data);
                    }
                }else if (type === 'status'){
                    setConnected(data);
                }
            });
        });
    } else {
        alert("Service Worker non suporte pas de chat disponible pour votre navigateur");
    }

    fetchDiscussions();
    fetchMessages();

    // Écoutez l'événement keydown pour mettre à jour le state des touches
    // window.addEventListener('keydown', handleKeyDown);
    // window.addEventListener('keyup', handleKeyUp);


    return () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.removeEventListener('message', () => {});
        }
        
    };
}, [id, selectedChat?.id, user_informations?.id]);

// Fonction pour se connecter via WebSocket
const handleConnect = () => {
    if (serviceWorker) {
        serviceWorker.postMessage({
            type: 'initiate_ws',
            data: {
                url: wsBackendUrl,
                id: user_informations.id
            }
        });
        serviceWorker.postMessage({
            type: 'status',
            data: null
        });
    } else if (!isMobile) {
        alert('Service worker non prêt');
    }
};
const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        if (!e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
            // Envoyer le message
            e.preventDefault();
            handleSendMessage();
        }
    }
};
// Modifier handleSendMessage
const handleSendMessage = () => {
    if (message.trim() && selectedChat && serviceWorker) {
        if(!connected){
            toast.current?.show({
                severity: "warn",
                summary: "Erreur",
                detail: "Vous devez être connecté pour envoyer un message",
            });
            console.error("WebSocket is not connected");
            return;
        }
        const newMessage = {
            contenu: message,
            UtilisateurId: user_informations.id,
            ChatRoomId: selectedChat.id
        };

        // Envoi du message via le Service Worker
        serviceWorker.postMessage({
            type: 'send_message',
            data: { message: newMessage }
        });

        setMessage("");
    }
};


const MessageComponent = ({ msg }) => {
    const messageWithBreaks = msg?.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index < msg?.split('\n').length - 1 && <br />}
        </React.Fragment>
    ));
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {messageWithBreaks}
        </div>
    );}

// Modifier handleSelectChat
const handleSelectChat = (chat) => {
    setSelectedChat(chat);

    if (serviceWorker) {
        // Envoyer la requête via le Service Worker
        serviceWorker.postMessage({
            type: 'read_messages',
            data: {
                UtilisateurId: user_informations.id,
                ChatRoomId: chat.id
            }
        });
    }
    
    setUnReadCount((prevUnreadCount) => ({...prevUnreadCount, [chat.id]: 0 }));
};



    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <div id="main" style={{ height: '100hv' }}>
            <NavBar/>
            <Toast ref={toast} />
            <div className="apropos" style={{ display: "flex", height: "100%", backgroundColor: "#f5f5f5", marginTop:"8rem" }}>
                {!selectedChat || !isMobile ? (
                    <div style={{ width: isMobile  ? "100%": "30%", borderRight: "1px solid #ddd", backgroundColor: "#fff",  marginTop:  "initial" }}>
                        <div style={{ padding: "10px", backgroundColor: "#95c73a", color: "#fff" }}>
                            <h3>Discussions</h3>
                        </div>
                        <div>
                                    {/* Bouton et statut */}
                                <div style={{ padding: "10px", borderBottom: "1px solid #ddd", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ fontWeight: "bold", color: connected ? "green" : "red" }}>
                                        {connected ? "En ligne" : "Hors ligne"}
                                    </span>
                                    {!connected && (
                                        <button
                                            onClick={handleConnect}
                                            style={{
                                                padding: "5px 10px",
                                                backgroundColor: "#007BFF",
                                                color: "#fff",
                                                border: "none",
                                                borderRadius: "5px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            Connecter
                                        </button>                                        
                                    )}
                                    {connected && (
                                        <Badge  severity="contrast"></Badge>
                                    )}
                                </div>

                            {discussions.map((chat) => (
                                <div key={chat.id} onClick={() => handleSelectChat(chat)} style={{ padding: "10px", borderBottom: "1px solid #ddd", cursor: "pointer", backgroundColor: selectedChat?.id === chat.id ? "#e2d307" : "#fff" }}>
                                    <div style={{ display: "flex", alignItems: "center", cursor:"pointer" }}>
                                        <Avatar shape="circle" style={{marginRight:"0.7rem"}}
                                        // label={user_informations.role === "Medecin" ? chat.Patient.Utilisateur.nom[0] : user_informations.role === "Patient" ? chat.Medecin.Utilisateur.nom[0] : user_informations.role} style={{ backgroundColor: "#95c73a", marginRight: "10px" }} 
                                        image={baseURL+`${ user_informations.role === "Medecin" ? chat.Patient.Utilisateur.photo : user_informations.role === "Patient" ? chat.Medecin.Utilisateur.photo : user_informations.role}`} />
                                        <div style={{ flex: 1 }}>
                                            <h5 style={{ margin: "0" }}>{user_informations.role === "Medecin" ? chat.Patient.Utilisateur.nom : user_informations.role === "Patient" ? chat.Medecin.Utilisateur.nom : user_informations.role} {unReadCount[chat.id] > 0 && <Badge style={{ float: "right" }} value={unReadCount[chat.id] || 0} severity="success" />}</h5>
                                            <p style={{ margin: "0", fontSize: "12px", color: "#777" }}>{messagesByChat[chat.id]?.length > 0 ? messagesByChat[chat.id][messagesByChat[chat.id]?.length-1]?.contenu: null}  <small style={{ float: "right" }}>{new Date(chat.Messages[0]?.date_envoi).toLocaleString() }</small></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
                <div style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: "#fff" , }}>
                    <div style={{ padding: "10px", backgroundColor: "#95c73a", color: "#fff", display: "flex", alignItems: "center", marginTop: "initial" }}>
                        {isMobile && selectedChat && (
                            <Button icon="pi pi-arrow-left" className="p-button-text p-button-plain" style={{ color: "#fff" }} onClick={() => setSelectedChat(null)} />
                        )}
                        <h3 style={{ margin: "0", marginLeft: isMobile ? "10px" : "0" }}>{selectedChat ? selectedChat.nom : "Sélectionnez une discussion"}</h3>
                    </div>
                    <div style={{ flex: 1, padding: "10px", overflowY: "auto", backgroundColor: "#f0f5f7", paddingBottom:"8rem" }}>
                    {selectedChat ? (
                        <>
                        {messagesByChat[selectedChat.id]?.map((msg, ind) => (
                            <div key={msg.id} 
                            ref={ind === messagesByChat[selectedChat.id].length - 1 ? lastMessageRef : null}
                            style={{ display: "flex", justifyContent: user_informations.id === msg.UtilisateurId ? "flex-end" : msg.UtilisateurId === null ? "center" : "flex-start", marginBottom: "10px" }}
                            onMouseEnter={()=>{setSelectedChatMessage(msg)}}
                            onMouseLeave={()=>{setSelectedChatMessage(null)}}
                            onContextMenu={(e)=>{ e.preventDefault(); setSelectedChatMessage(msg); messageMenu.current?.show(e)}}
                            >
                            <ContextMenu model={messageMenuItems} popup ref={messageMenu} id={"menu_msg_"+ ind} />  

                               {msg.UtilisateurId === null  && <div style={{display:"flex", flex:"row", justifyContent:"space-between", width:"100%"}}>
                                    {msg.UtilisateurId === null  && <hr style={{width:"30%"}} />}
                                    <div style={{ margin: "0" }}>{msg.contenu}</div>
                                    {msg.UtilisateurId === null  && <hr style={{width:"30%"}}/>}
                                </div>
                                }
                            <div style={{
                                backgroundColor: `${msg.UtilisateurId === user_informations.id ? "#95c73a" : msg.UtilisateurId === null ? "initial" : "#e6e6e6"}`,
                                color: `${msg.UtilisateurId === user_informations.id ? "#fff" : "#000"}`,
                                padding: "8px 12px",
                                borderRadius: "15px",
                                maxWidth:  msg.UtilisateurId === null ? "100%": "75%",
                                boxShadow: msg.UtilisateurId === null ? "none": "0px 1px 2px rgba(0, 0, 0, 0.1)",
                                flexWrap: "wrap",
                                flexDirection: "column",
                                border:  msg.UtilisateurId === null ? "none": "1px solid #ddd",
                                position: "relative"
                            }}>
                                
                                {msg.UtilisateurId !== null  && <div style={{display:"flex", flex:"row", justifyContent:"space-between", width:"100%"}}>
                                    {msg.UtilisateurId === null  && <hr />}
                                    <div style={{ margin: "0" ,
                                        overflow: "hidden",     wordWrap: "break-word", 
                                        overflowWrap: "break-word",  
                                        wordWrap:"break-word", maxWidth:"100%" }}>
                                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>                                            
                                                {msg.type === "image" ? <img  style={{cursor:"pointer"}}  onClick={()=>{setShowOverlay(true); setSelectedMediaIndex(ind)}} src={baseURL+ msg.src}  alt={"Image of " + msg.Utilisateur?.nom} width={300} height={200}/>  : null}
                                                {msg.type === "Audio" ? <audio  style={{cursor:"pointer"}} src={baseURL + msg.src} alt={"audio of " + msg.Utilisateur.nom} />  : null}
                                                {msg.type === "video" ? <video controls width="250"  style={{cursor:"pointer"}}  alt={"video of " + msg.Utilisateur.nom} > <source src={baseURL + msg.src} /> </video>  : null}
                                                <MessageComponent msg={msg.contenu  }/>
                                            </div>
                                    
                                    </div>
                                    {msg.UtilisateurId === null  && <hr />}
                                </div>}
                                {msg.UtilisateurId !== null && <><small style={{ margin: "0", textAlign: "right", fontSize: "10px", color: "#777" }}>{new Date(msg?.date_envoi).toLocaleTimeString()}</small>{msg.UtilisateurId === user_informations.id &&<small style={{float:"right", color:`${ msg.lu ? "#00d9ff": "Highlight"}`, marginLeft:"8px"}}>{msg.recu ? <FaCheckDouble style={{  marginLeft: "8px" }} /> : <i className="pi pi-check"></i>}</small>}</>}
                            </div>
                            </div>
                        ))}
                        </>
                    ) : (
                        <div style={{ textAlign: "center", marginTop: "20px", color: "#999" }}>
                        <p>Aucune discussion sélectionnée</p>
                        </div>
                    )}
                    </div>

                    {selectedChat && (

                    <>
                        {
                            uploading && selectedChat && <><Chip label={`${uploadPercentage} %` } /><ProgressBar mode="indeterminate" style={{height:"3px"}} /></>
                        }
                        <div style={{ padding: "10px", borderTop: "1px solid #ddd", display: "flex", alignItems: "center", backgroundColor: "#fff", position: "fixed", width:isMobile && selectedChat? "100%":"70%", bottom: 0 }}>
                            <Menu model={menuItems} popup ref={menu} id="popup_menu" />  {/* Menu pour les types de message */}

                            <input
                            type="file"
                                style ={{display:"none"}}
                                ref={imageInputRef}
                                onChange={onUploadFile}
                                accept="image/*"
                                multiple
                            />
                            
                            <input
                            type="file"
                                style ={{display:"none"}}
                                ref={videoInputRef}
                                onChange={onUploadFile}
                                accept="video/*"
                                multiple
                            />
                            
                            <input
                            type="file"
                                style ={{display:"none"}}
                                ref={documentRef}
                                onChange={onUploadFile}
                                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,"
                                multiple
                            />
                            <Button icon="pi pi-plus" className="p-button-sm p-button-text p-button-rounded" onClick={(e) => menu.current.toggle(e)} /> {/* Bouton "+" */}
                            <InputTextarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyUp={handleKeyPress}
                            placeholder="Votre message"
                            className="p-inputtext-sm"
                            style={{ flex: 1, marginRight: "10px" }}
                            />
                            { message.length > 0 ? <Button icon="pi pi-send" className="p-button-sm" onClick={handleSendMessage} /> 
                                                : <Button icon='pi pi-microphone' className="p-button-sm p-button-text p-button-rounded" onClick={handleAudio} />
                            }
                        </div>
                    </>
                    )}
                    </div>
                    <Dialog header={
                        <div style={{display:"flex", justifyContent:"space-between" }}>
                            <h3 style={{margin: "0", marginLeft: isMobile? "10px" : "0" }}>Media</h3>
                            {/* Bonton de telechargement */}
                            <div style={{float:"right"}}> 
                                <Button icon="pi pi-download" className="p-button-sm p-button-text p-button-rounded" onClick={handleDownloadMedia} disabled={selectedMediaIndex === null} />
                            </div>
                        </div>
                    } visible={showOverlay} position='center' style={{ width: '100vw', height:'100hv' }} onHide={() => {if (!showOverlay) return; setShowOverlay(false); }}  draggable={false} resizable={false}>
                        <p className="m-0">
                            <MediaCarousel messages={messagesByChat[selectedChat?.id]} ind={selectedMediaIndex} />
                        </p>
                    </Dialog>
            </div>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default ChatPage;

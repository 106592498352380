import React, { useState, useEffect, useRef } from 'react';
import {  useNavigate } from 'react-router-dom';
import axios from './../utils/axiosConfig';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';
import { Divider } from 'primereact/divider';

const daysOfWeek = [
  { label: 'Lundi', value: 'Lundi' },
  { label: 'Mardi', value: 'Mardi' },
  { label: 'Mercredi', value: 'Mercredi' },
  { label: 'Jeudi', value: 'Jeudi' },
  { label: 'Vendredi', value: 'Vendredi' },
  { label: 'Samedi', value: 'Samedi' },
  { label: 'Dimanche', value: 'Dimanche' }
];

const sexes = [
  { label: 'Homme', value: 'Homme' },
  { label: 'Femme', value: 'Femme' },
];

const availableHours = [
  '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'
];

const RegisterMed = () => {
  const [loading, setLoading] = useState(false);
  const [userInf, setUserInf] = useState({
    nom: '',
    prenom: '',
    email: '',
    sexe: '',
    mot_de_passe: '',
    role: 'Medecin',
    numero_de_telephone: '',
    service: '',
    date_de_naissance: null,
    tranchesHoraires: {},
  });
  const [services, setServices] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');
  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        const serviceOptions = response.data.map(service => ({ label: service.nom, value: service.id }));
        setServices(serviceOptions);
      } catch (error) {
        console.error('Erreur lors de la récupération des services:', error);
      }
    };
    fetchServices();
  }, []);

  const handleChange = (event) => {
    setUserInf({ ...userInf, [event.target.name]: event.target.value });
  };

  const handleAddTimeSlot = () => {
    if (selectedDay && startHour && endHour) {
      // Créez des objets Date pour la comparaison
      const startDateTime = new Date(`1970-01-01T${startHour}:00`);
      const endDateTime = new Date(`1970-01-01T${endHour}:00`);
  
      // Vérifiez si l'heure de début est inférieure à l'heure de fin
      if (startDateTime >= endDateTime) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'L\'heure de début doit être inférieure à l\'heure de fin.', life: 3000 });
        return;
      }
  
      // Si la vérification est réussie, ajoutez la plage horaire
      setUserInf(prevState => ({
        ...prevState,
        tranchesHoraires: {
          ...prevState.tranchesHoraires,
          [selectedDay]: [
            ...(prevState.tranchesHoraires[selectedDay] || []),
            { startHour, endHour }
          ]
        }
      }));
      setStartHour('');
      setEndHour('');
    }
  };

  const handleRemoveTimeSlot = (day, index) => {
    setUserInf(prevState => ({
      ...prevState,
      tranchesHoraires: {
        ...prevState.tranchesHoraires,
        [day]: prevState.tranchesHoraires[day].filter((_, i) => i !== index)
      }
    }));
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]{5,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ['nom', 'prenom', 'email', 'mot_de_passe', 'sexe', 'numero_de_telephone', 'date_de_naissance'];

    for (let field of requiredFields) {
      if (!userInf[field]) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: `Le champ ${field} est requis.`, life: 3000 });
        return;
      }
    }

    if (!validatePassword(userInf.mot_de_passe)) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Le mot de passe doit contenir au moins 5 caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
        life: 3000
      });
      return;
    }



    setLoading(true);

    try {
      const response = await axios.post("/register", userInf, {
        headers:{
          'Authorization': 'Bearer '+ localStorage.getItem('token')
        }
      });
      // localStorage.setItem("user_informations", JSON.stringify(response.data.utilisateur));
      // localStorage.setItem("token", response.data.token);
      toast.current.show({
        severity: 'success',
        summary: 'Succès',
        detail: 'Enregistrement effectué avec succès !',
        life: 3000
      });
      setTimeout(() => navigate("/admin-medecins-list"), 2000);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Erreur lors de l'enregistrement.",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const footer = (
    <>
        <Divider />
        <p className="mt-2">Suggestions</p>
        <ul className="pl-2 ml-2 mt-0 line-height-3">
          <li>Minimum 8 caractères</li>
          <li>Au moins une majuscule</li>
          <li>Au moins une minuscule</li>
          <li>Au moins un chiffre</li>
          <li>Au moins un caractère spécial</li>
        </ul>
    </>
);

  return (
    <div>
      <div className="app-wrapper">
        <HeaderAdmin />
        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Enregistrement Médecin</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="app-content">
            <div className="container-fluid">
              <div className="row justify-content-center">
              <div>
                <Toast ref={toast} />
                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                  <Card className="p-card" style={{ padding: '2rem' }}>
                    <h3>Ajouter un médecin</h3>

                    <div className="mb-4">
                      <InputText id="register-nom" name="nom" value={userInf.nom} onChange={(e) => setUserInf({ ...userInf, nom: e.target.value })} placeholder="Nom *" className="w-100" />
                    </div>

                    <div className="mb-4">
                      <InputText id="register-prenom" name="prenom" value={userInf.prenom} onChange={(e) => setUserInf({ ...userInf, prenom: e.target.value })} placeholder="Prénom *" className="w-100" />
                    </div>

                    <div className="mb-4">
                      <InputText id="register-email" name="email" value={userInf.email} onChange={(e) => setUserInf({ ...userInf, email: e.target.value })} placeholder="Email *" className="w-100" />
                    </div>

                    <div className="mb-4">
                      <InputText id="register-telephone" name="numero_de_telephone" value={userInf.numero_de_telephone} onChange={(e) => setUserInf({ ...userInf, numero_de_telephone: e.target.value })} placeholder="Numéro de téléphone *" className="w-100" />
                    </div>

                    <div className="mb-4">
                      <Dropdown id="register-service" name="service" value={userInf.service} options={services} onChange={(e) => setUserInf({ ...userInf, service: e.value })} placeholder="Sélectionnez le service *" className="w-100" />
                    </div>

                    <div className="mb-4">
                      <Calendar id="date-naissance" name="date_de_naissance" value={userInf.date_de_naissance} onChange={(e) => setUserInf({ ...userInf, date_de_naissance: e.value })} placeholder="Date de naissance *" className="w-100" />
                    </div>
                    <div className="mb-4">
                          <Dropdown id="register-sexe" name="sexe" value={userInf.sexe} options={sexes} onChange={(e) => setUserInf({ ...userInf, sexe: e.value })} placeholder="Sélectionnez le sexe *" className="w-100" />
                    </div>
                    <div className="mb-4">
                          <Password id="register-password" style={{width: '31.5rem'}} name="mot_de_passe" header={<div className="font-bold mb-3">Choisissez un mot de passe: </div>}  toggleMask value={userInf.mot_de_passe} onChange={handleChange}   footer={footer} placeholder="Entrez votre mot de passe *" />
                        </div>

                    {/* Gestion des plages horaires */}
                    <div className="mb-4">
                      <h5>Ajouter des plages horaires</h5>
                      <Dropdown id="day" value={selectedDay} options={daysOfWeek} onChange={(e) => setSelectedDay(e.value)} placeholder="Sélectionnez un jour" className="w-100 mb-3" />

                      <Dropdown id="start-hour" value={startHour} options={availableHours.map(h => ({ label: h, value: h }))} onChange={(e) => setStartHour(e.value)} placeholder="Heure de début" className="w-100 mb-3" />
                      
                      <Dropdown id="end-hour" value={endHour} options={availableHours.map(h => ({ label: h, value: h }))} onChange={(e) => setEndHour(e.value)} placeholder="Heure de fin" className="w-100 mb-3" />
                      
                      <Button label="Ajouter une plage horaire" icon="pi pi-plus" className="w-100" onClick={handleAddTimeSlot} />
                    </div>

                    {/* Affichage des plages horaires */}
                    {Object.keys(userInf.tranchesHoraires).length > 0 && (
                      <div className="mb-4">
                        <h5>Plages horaires ajoutées</h5>
                        {Object.entries(userInf.tranchesHoraires).map(([day, slots]) => (
                          <div key={day} className="mb-3">
                            <h6>{day}</h6>
                            <ul>
                              {slots.map((slot, index) => (
                                <li key={index}>
                                  <div style={{alignContent:"center"}}>{slot.startHour} - {slot.endHour} <Button icon="pi pi-trash" style={{marginLeft:"5rem"}} className="p-button-danger p-button-text mt-2" onClick={() => handleRemoveTimeSlot(day, index)} /></div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}



                    <div className="mt-4">
                      <Button label="Enregistrer le médecin" icon="pi pi-check" className="w-100 p-button-success" onClick={handleSubmit} />
                    </div>
                  </Card>
                </div>
              </div>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    </div>
  );
}

export default RegisterMed;

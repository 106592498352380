import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PanelMenu } from 'primereact/panelmenu';
import { Menu } from "primereact/menu";
import './admin_header.css';
import Notifications from "../utils/notifications";

export default function HeaderAdmin() {
  const navigate = useNavigate();

  // Redirect if no admin is logged in
  useEffect(() => {
    const admin = localStorage.getItem("admin_user_inf");
    if (!admin) {
      alert("Vous n'avez pas accès à cette page.");
      navigate("/");
    }
  }, [navigate]);

  // Logout function
  const logout = () => {
    localStorage.removeItem("admin_user_inf");
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (let registration of registrations) {
              registration.unregister().then(function() {
                  console.log('Service worker désinstallé.');
              }).catch(function(error) {
                  console.error('Erreur lors de la désinstallation du service worker:', error);
              });
          }
      }).catch(function(error) {
          console.error('Erreur lors de la récupération des enregistrements du service worker:', error);
      });
    }
    navigate("/login");
  };

  // Sidebar menu items
  const menuItems = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/admin'); }
    },
    {
      label: 'Information Sanitaires',
      icon: 'pi pi-fw pi-book',
      items: [
        {
          label: 'Liste',
          icon: 'pi pi-fw pi-list',
          command: () => { navigate('/admin-info_sanitaire-list'); }
        },
        {
          label: 'Ajouter',
          icon: 'pi pi-fw pi-plus',
          command: () => { navigate('/admin-info_sanitaire-ajout'); }
        }
      ]
    },
    {
      label: 'Médecins',
      icon: 'pi pi-fw pi-crown',
      items: [
        {
          label: 'Liste',
          icon: 'pi pi-fw pi-list',
          command: () => { navigate('/admin-medecins-list'); }
        },
        {
          label: 'Ajouter',
          icon: 'pi pi-fw pi-plus',
          command: () => { navigate('/admin-add-medecin/'); }
        }
      ]
    },
    {
      label: 'Associations',
      icon: 'pi pi-fw pi-users',
      items: [
        {
          label: 'Liste',
          icon: 'pi pi-fw pi-list',
          command: () => { navigate('/admin-partners'); }
        },
        {
          label: 'Ajouter',
          icon: 'pi pi-fw pi-plus',
          command: () => { navigate('/admin-partnerAdd/'); }
        }
      ]
    },
    {
      label: 'Bien-Être',
      icon: 'pi pi-fw pi-heart',
      items: [
        {
          label: 'Quizzs',
          icon: 'pi pi-fw pi-question',
          items: [
            {
              label: 'Ajouter',
              icon: 'pi pi-fw pi-plus',
              command: () => { navigate('/admin-questionGroupAdd'); }
            },
            {
              label: 'Liste',
              icon: 'pi pi-fw pi-list',
              command: () => { navigate('/admin-quizzList_admin'); }
            }
          ]
        },
        {
          label: 'Groupe Question',
          icon: 'pi pi-fw pi-folder',
          items: [
            {
              label: 'Ajouter',
              icon: 'pi pi-fw pi-plus',
              command: () => { navigate('/admin-questionGroupAdd'); }
            },
            {
              label: 'Liste',
              icon: 'pi pi-fw pi-list',
              command: () => { navigate('/admin-questiongroups'); }
            }
          ]
        },
        {
          label: 'Questions',
          icon: 'pi pi-fw pi-question-circle',
          items: [
            {
              label: 'Ajouter',
              icon: 'pi pi-fw pi-plus',
              command: () => { navigate('/admin-questionAdd'); }
            },
            {
              label: 'Liste',
              icon: 'pi pi-fw pi-list',
              command: () => { navigate('/admin-questions'); }
            }
          ]
        },
        {
          label: 'Statistiques',
          icon: 'pi pi-fw pi-chart-bar',
          command: () => { navigate('/admin-statique_questions'); }
        }
      ]
    },
    {
      label: 'Système d\'Alerte',
      icon: 'pi pi-fw pi-exclamation-triangle',
      items: [
        {
          label: 'Rendez-vous acceptes',
          icon: 'pi pi-fw pi-verified',
          command: () => { navigate('/rdv-non-traites'); }
        }
      ]
    }
  ];

  // Sample dropdown data for messages and notifications
  const messages = [
    { label: 'Message 1', icon: 'pi pi-envelope' },
    { label: 'Message 2', icon: 'pi pi-envelope' },
    { label: 'Message 3', icon: 'pi pi-envelope' }
  ];



  // State and references to manage dropdown visibility
  const messageMenu = useRef(null);

  return (
    <>
      {/* Header */}
      <nav className="app-header navbar navbar-expand bg-body">
        <div className="container-fluid">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" data-lte-toggle="sidebar" to="#" role="button">
                <i className="pi pi-bars"></i>
              </Link>
            </li>
            <li className="nav-item d-none d-md-block">
              <Link to="/admin" className="nav-link">Accueil</Link>
            </li>
            <li className="nav-item d-none d-md-block">
              <Link to="/" className="nav-link">Revenir au site</Link>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            {/* Messages Dropdown */}
            <li className="nav-item dropdown">
              <Link className="nav-link" to="#" onClick={(e) => messageMenu.current.toggle(e)}>
                <i className="pi pi-envelope"></i>
                <span className="badge badge-danger navbar-badge">3</span>
              </Link>
              <Menu model={messages} popup ref={messageMenu} />
            </li>

            {/* Notifications Dropdown */}
            <Notifications />
            <li className="nav-item">
              <Link className="nav-link" to="#">
                <i className="pi pi-user"></i>
              </Link>
            </li>
            <li className="nav-item">
              <button className="nav-link" onClick={logout}>
                <i className="pi pi-sign-out"></i> Déconnexion
              </button>
            </li>
          </ul>
        </div>
      </nav>

      {/* Sidebar */}
      <aside className="app-sidebar sidebar-dark bg-dark sidebar-wrapper h-100">
        <div className="sidebar-brand">
          <Link to="/admin" className="brand-link">
            <img src="/logo512.png" alt="Admin Logo" className="brand-image opacity-75 shadow" />
            <span className="brand-text fw-light" style={{ color: '#ffff', fontWeight: 'bold' }}>YohedaCare Admin</span>
          </Link>
        </div>

        <div className="menu-container" style={{ flexGrow: 1 }}>
          {/* Collapsible Sidebar Menu */}
          <PanelMenu model={menuItems} style={{ width: '100%' }} />
        </div>

        {/* Button de déconnexion en bas */}
        <div className="logout-container" style={{ padding: '10px' }}>
          <button
            className="btn btn-block btn-danger"
            style={{ width: '100%' }}
            onClick={logout}
          >
            <i className="pi pi-sign-out"></i> Déconnexion
          </button>
        </div>
      </aside>
    </>
  );
}

import axios from 'axios';
const axiosInstance = axios.create({
    baseURL: 'https://yoheda-back-37zx.onrender.com', 
});

const frontendUrl = 'https://care.yohedahealth.org';
// const frontendUrl = 'http://localhost:3000';
const baseURL = "https://yoheda-back-37zx.onrender.com";
const wsBackendUrl = "wss://yoheda-back-37zx.onrender.com";


// const frontendUrl = 'http://192.168.1.103:3000';
// const baseURL = "http:/localhost:5000";//
// const wsBackendUrl = "ws://localhost:5000";

axiosInstance.interceptors.response.use(
    response => {
        // Si la réponse est valide, juste retourner la réponse
        return response;
    },
    error => {
        if (error.response && ( error.response.status === 403)) {
            // Rediriger vers la page de login et desinstaller le service worker
            if(window.location.pathname !== '/login') {
                // Désinstallation du service worker
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistrations().then(function(registrations) {
                        for (let registration of registrations) {
                            registration.unregister().then(function() {
                                console.log('Service worker désinstallé.');
                            }).catch(function(error) {
                                console.error('Erreur lors de la désinstallation du service worker:', error);
                            });
                        }
                    }).catch(function(error) {
                        console.error('Erreur lors de la récupération des enregistrements du service worker:', error);
                    });
                }
            
                // Redirection vers la page de login
                window.location.href = `${frontendUrl}/login`;
            }
        }
        // Rejeter la promesse pour propager l'erreur
        return Promise.reject(error);
    }
);
export {baseURL, wsBackendUrl, frontendUrl};
export default axiosInstance;

import React from 'react'
import { Link } from 'react-router-dom';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';

const About = () => {
  return (
    <div>
        <NavBar />
         <div style={{height: '100%'}}>
        <div class="container container-title pt-5 pb-5" >
            <div class="apropos d-flex align-items-center" style={{marginTop:'8rem'}}>
                <h1 class="fs-0 fw-bolder mb-0" style={{fontWeight:'bold', color:'#ffa500'}}>Yoheda</h1>
            </div>
        </div> 

         {/* Context Section */}
         <div class="container pt-0 pb-5">
            <div class="w-100" style={{margin: "0 auto;", height:'60%'}}>
            <img  src="/data/applications/logos/11090/hd/background-yoheda.jpg" 
            class="w-100 rounded img-fluid" alt="Yoheda Care" title="Yoheda Care"></img>
		    </div>
        </div>

        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Notre Vision</h3>
                <p style={{textIndent: '25px'}}>La vision de YOHEDA est d’être un acteur majeur et incontournable de l’atteinte des ODD en matière de santé et de développement des jeunes à l’horizon 2035.</p>
            </div>
        </div>

        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Notre Mission</h3>
                <p style={{textIndent: '25px'}}>YOHEDA se donne pour mission d’accompagner et de promouvoir les sciences, les technologies, la formation en vue des actions fortes par les jeunes pour l’amélioration de la santé et du développement en Afrique, dans un environnement plus sain.</p>
            </div>
        </div>

        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Nos cibles</h3>
                <p style={{textIndent: '25px'}}>Nos cibles sont principalement les jeunes, les femmes et les personnes vulnérables.</p>
            </div>
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Besoin de Plus ?</h3>
                <p style={{textIndent: '25px'}}>Pour en savoir d'avantage sur Youth For Health And Developement Of Africa (Yoheda), suivez ce <Link to={"https://www.yohedahealth.org/"} target='_blank'>Site de yohedahealth</Link></p>
            </div>
        </div>
        <div class="container container-title pt-5 pb-5">
            <div class="d-flex align-items-center">
                <h1 class="fs-0 fw-bolder mb-0" style={{fontWeight:'bold', color:'#ffa500'}}>YohedaCare</h1>
            </div>
        </div>  
        

        <div class="container pt-0 pb-5">
            <div class="w-100" style={{margin: "0 auto;"}}>
            <img  src="/assets/img/yoheda-bertoua.jpeg" 
            class="w-100 rounded img-fluid" alt="Yoheda Care" title="Yoheda Care"></img>
		    </div>
        </div>
       
        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Description</h3>
                <p style={{textIndent: '25px'}}>Développée dans le cadre du projet Restoring Hope, Health and Rights soutenu par le Fonds Canadien d'initiatives Locales (FCIL), YohedaCare est une plateforme numérique de consultations à distance et d'informations sanitaires de qualité; adaptée aux difficultés des personnes vulnérables. Elle se veut proche des couches vulnérables, garantissant la confidentialité et la sécurité des données.En combinant des services d’information sanitaire et de consultations à distance, YohedaCare vise à réduire les barrières géographiques, économiques et sociales qui limitent l’accès aux soins.</p>
            </div>
        </div>

        {/* Objectives Section */}
        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Objectifs</h3>
                <p>Les objectifs de Yoheda Care sont multiples :</p>
                <ul>
                  <li style={{fontWeight:'bold'}}>Accès à l'information
                  </li>
                  <p>Fournir un accès facile et gratuit à des informations de santé fiables et pratiques, adaptées aux besoins spécifiques des personnes vulnérables.</p>
                  
                  <li style={{fontWeight:'bold'}}>Soutien et Accompagnement</li>
                  <p>Offrir un soutien et un accompagnement individualisé aux personnes vulnérables par le biais de la plateforme numérique, favorisant leur bien-être et leur autonomie.</p>
                  <li style={{fontWeight:'bold'}}>Prévention et Promotion de la Santé</li>
                  <p>Contribuer à la prévention des maladies et à la promotion de la santé en général, en particulier chez les populations vulnérables.</p>
                </ul>
            </div>
        </div>

        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Caractéristiques</h3>
                <ul>
                    <li style={{fontWeight:'bold'}}>Informations sanitaires personnalisées :</li>
                    <p> Accès à des articles, vidéos et ressources adaptées aux besoins spécifiques des utilisateurs.</p>
                    <li style={{fontWeight:'bold'}}>Consultations en ligne :</li>
                    <p>Mise en relation avec des professionnels de santé qualifiés pour des consultations médicales et psychologiques via chat, ou audio.</p>
                    <li style={{fontWeight:'bold'}}>Suivi des patients :</li>
                    <p>Outils pour suivre les symptômes et les rendez-vous ; évaluer le bien être .</p>
                    <li style={{fontWeight:'bold'}}>Accessibilité :</li>
                    <p>Interface conviviale, optimisée pour les smartphones et les ordinateurs, prenant en compte les besoins des utilisateurs peu familiers avec la technologie.</p>
                </ul>
                
            </div>
        </div>

        

        {/* Targets Section */}
        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Cibles</h3>
                <p>Les principales cibles de Yoheda Care sont :</p>
                <ul>
                  <li style={{fontWeight:'bold'}}>Personnes atteintes du VIH</li>
                  <p>Les personnes vivant avec le VIH sont confrontées à des défis spécifiques en matière de santé, d'accès aux soins et de stigmatisation. YohedaCare vise à leur fournir les informations et le soutien nécessaires pour améliorer leur bien-être.</p>
                  <li style={{fontWeight:'bold'}}>Personnes atteintes de handicap</li>
                  <p>Les personnes atteintes de handicap se voient souvent limitées du fait de leur condition, et peuvent avoir un accès limité aux service de santé. YohedaCare vise à leur fournir un accompagnement psychologique, ainsi qu’à leurs proches.</p>
                  <li style={{fontWeight:'bold'}}>Réfugiés et déplacés internes</li>
                  <p>Les réfugiés et les déplacés internes sont souvent confrontés à des problèmes de santé mentale et à des difficultés d'accès aux services de santé. YohedaCare vise à leur fournir les ressources et le soutien nécessaires pour améliorer leur bien-être.</p>
                </ul>
            </div>
        </div>


        {/* Stakeholders Section */}
        <div class="container pt-0 pb-5">
            <div class=" w-100" style={{margin: "0 auto;"}}>
	            <h3>Collaboration avec les acteurs locaux</h3>
                <p style={{textIndent: '25px'}}><strong>YohedaCare </strong> travaille en partenariat avec des organisations locales, des professionnels de santé et des institutions gouvernementales pour s'assurer que les services proposés répondent aux besoins spécifiques des communautés ciblées.</p>
                                
            </div>
        </div>

        

        {/* Contact Section */}
        <div class=" pt-0 pb-5">
            <div class="col-button w-100 bg-light text-dark px-4 px-lg-0 pt-5 pb-5 text-center" style={{margin: "0 auto;"}}>
		        <div class="h3 mt-5">Besoin de renseignements ?</div>
			    <h6 class="fw-lighter mb-5" style={{maxWidth:"580px",marginLeft:"auto",marginRight:"auto"}}><p>Pour en savoir plus sur nos activités ou pour participer à nos initiatives, n’hésitez pas à nous contacter. Yoheda Care est là pour vous soutenir.</p></h6>
				<Link to="/contact" class="btn btn-lg mb-5 btn-primary  rounded-1 btn-text-transform-1 text-light">Nous contacter</Link>
			</div>
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default About;

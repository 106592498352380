import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance, { baseURL } from '../utils/axiosConfig'; 
import { FaTimesCircle } from 'react-icons/fa'; 

const Assistance = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [partenaires, setPartenaires] = useState([]);
  const [groupedPartenaires, setGroupedPartenaires] = useState({});
  const [carouselItems, setCarouselItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(true);  

  const navigate = useNavigate(); // Correction: utiliser la fonction navigate
  // Exemple de données pour le carrousel avec couleurs et numéros d'urgence


    // Fonction pour vérifier si l'utilisateur est authentifié
  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  // Fonction pour gérer la redirection en fonction de l'authentification
  const handleRdvClick = () => {
    if (isAuthenticated()) {
      navigate('/rdv'); // Redirige vers la page de rendez-vous si authentifié
    } else {
      navigate('/login'); // Redirige vers la page de login sinon
    }
  };

  // Fonction pour définir l'élément à afficher dans le carrousel
const itemTemplate = (item) => {
  return (
    <div
    className='testr'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '8rem',
        width: '95%', // Laisser l'élément prendre toute la largeur disponible
        maxWidth: '97%', // Limiter la largeur à 100% du parent
        backgroundImage: `url(${baseURL + '/' + item.image})`, // Image de fond
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        color: '#648577', // Couleur du texte
        fontSize: '1rem', // Taille du texte ajustée pour une meilleure lisibilité sur petits écrans
        fontWeight: 'bold', // Poids du texte
        textAlign: 'center', // Alignement du texte
        padding: '15px', // Réduction du padding pour compenser les petits écrans
        margin: '0 auto', // Centrage automatique
        boxSizing: 'border-box', // Garantir que le padding n'affecte pas la largeur
      }}
    >
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-around'}}>
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{item.sigle} :</div>
        <div style={{ fontSize: '1.2rem' }}>{item.number}</div>
      </div>
    </div>
  );
};

  useEffect(() => {
    const fetchPartenaires = async () => {
      try {
        const response = await axiosInstance.get('/partenaires', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const partenairesData = response.data;

        // Mapper les données pour le carrousel
        const items = partenairesData.map((partenaire) => ({
          image: partenaire.photo, // Image du partenaire
          name: partenaire.nom, // Nom du partenaire
          number: partenaire.numero, // Numéro du partenaire
          sigle: partenaire.sigle, // Sigle du partenaire
        }));

        // Grouper les partenaires par ville
        const grouped = partenairesData.reduce((acc, partenaire) => {
          const ville = partenaire.ville || 'Autre';
          if (!acc[ville]) acc[ville] = [];
          acc[ville].push(partenaire);
          return acc;
        }, {});

        setGroupedPartenaires(grouped);
        setPartenaires(partenairesData);
        setCarouselItems(items);
        setLoading(false);
      } catch (error) {
        setError('Une erreur s\'est produite lors de la récupération des partenaires.');
        setLoading(false);
      }
    };

    fetchPartenaires();
  }, []);

  // Fonction de filtrage des partenaires
  const filterPartenaires = (partenaires) => {
    if (!searchTerm) return partenaires; // Si pas de terme de recherche, retourner tous les partenaires
    return partenaires.filter((partenaire) =>
      partenaire.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partenaire.sigle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partenaire.representant.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

 // Template pour afficher un partenaire
 const partenaireTemplate = (partenaire) => (
  <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-4 d-flex align-self-stretch">
    <div className="card bg-light text-dark thumbnail pe-pointer ajax-link-debug">
      <div className="ripple ripple-surface-light">
        <a href={partenaire.link} title={partenaire.nom}>
          <img 
            src={`${baseURL}/${partenaire.photo}`} 
            className="img-full" 
            alt={partenaire.nom} 
            title={partenaire.nom} 
          />
        </a>
      </div>
      <div className="card-body position-relative">
        <h2 className="fs-5 mb-2 text-h-ellipsis text-h-ellipsis-3">
          <a href={partenaire.link} title={partenaire.nom} className="text-dark fw-bolder">{partenaire.nom} ({partenaire.sigle})</a>
        </h2>
        <h3 className="fs-7 mb-2 fw-lighter" style={{ color: '#249646' }}>
          Leader : {partenaire.representant}
        </h3>
        <div className="fs-7">
          Description : 
            <div dangerouslySetInnerHTML={{__html: partenaire.description.substring(0, 100) }}></div>
        </div>
        <div className="fake-line-4"></div>
        <a href={partenaire.link} title={partenaire.nom} className="fs-7 position-absolute" style={{ bottom: '1.5rem', left: '1.5rem' }}>Contact : {partenaire.numero}</a>
      </div>
      <div className="fake-line-4">
        <Link to={`/villeinfo/${partenaire.id}`} title={partenaire.nom} className="fs-7" style={{ display: 'block', marginTop: '0rem', marginLeft:'1.5rem' }}>
          En savoir plus
        </Link>
      </div>
    </div>
  </div>
);
  return (
    <>
            <NavBar />
      <div id="main" style={{ height: 'auto' }}>

        <div className="bg-light text-dark px-2 py-3 p-lg-4 apropos" style={{marginTop:"8rem"}}>
          <div className="container ">
         <h3 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Nous ne sommes pas seuls</h3>
         <h5 className="font-weight-bold" style={{ fontSize: 'medium', fontWeight:'bold', color:'#ffa500', marginLeft: '1.5rem' }}>
           Vous pouvez également leur conctacter
         </h5>
      </div>
      </div>
      {isModalVisible && (
  <div
        className='infot'
    style={{
      position: 'fixed',
      top: '14.8rem', /* Centré verticalement */
      right: '0.3rem', /* Marges plus équilibrées */
      transform: 'translateY(-50%)', /* Ajustement pour centrer parfaitement */
      backgroundColor: '#fff',
      padding: '1.5rem',
      borderRadius: '0.2rem',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)', /* Ombre plus douce et plus large */
      zIndex: 9999,
      width: '100%',
      maxWidth: '50rem', /* Largeur maximale définie */
      maxHeight: '14rem', /* Hauteur maximale */
      overflowY: 'auto',
      fontFamily: 'Arial, sans-serif', /* Police moderne */
      animation: 'fadeInModal 0.5s ease-out', /* Animation d'apparition */
    }}
  >
    {/* Close Icon */}
    <FaTimesCircle
      onClick={() => setIsModalVisible(false)}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '1.5em',
        color: '#ff5722',
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
      }}
    />
    
    <p
      style={{
        fontWeight: '600',
        fontSize: '1.3em',
        color: '#ffa500',
        marginBottom: '0.6rem',
        textAlign: 'center', /* Centrage du texte */
        fontFamily: 'Segoe UI, sans-serif', /* Police professionnelle */
        animation: 'fadeInText 1s ease-out',
      }}
    >
      Bienvenue !
    </p>

    <p
      style={{
        fontSize: '1.5em',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '0.6rem',
        textAlign: 'center',
        animation: 'colorChange 3s ease-in-out infinite', /* Animation de couleur */
      }}
    >
      Votre confidentialité, notre priorité !
    </p>

    <p
      style={{
        fontSize: '1.1em',
        color: '#555',
        lineHeight: '1.6',
        textAlign: 'justify', /* Justification du texte pour un rendu plus professionnel */
        marginBottom: '1.5rem',
      }}
    >
      Votre confidentialité est essentielle. Toutes les conversations sont sécurisées et chiffrées, accessibles uniquement par vous et votre interlocuteur. Vos informations sont protégées et chaque utilisateur est vérifié grâce à un jeton unique.
    </p>
  </div>
)}

{/* Add styles for animations */}
<style jsx>{`
  @keyframes fadeInModal {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
  }

  @keyframes fadeInText {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes colorChange {
    0%, 100% { color: #333; }
    50% { color: #306941; }
  }

  /* Smooth Hover for the Close Icon */
  FaTimesCircle:hover {
    transform: scale(1.1); /* Effet de zoom */
  }
`}</style>

        {/* Rest of your page content */}


        <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem',
    boxSizing: 'border-box',
    marginTop: '6.5rem',
    width: '100%', // Assurez-vous que le parent prend toute la largeur disponible
    

  }}
>
  <Carousel
    value={carouselItems}
    itemTemplate={itemTemplate}
    numVisible={3} // Affichage de 3 éléments par défaut
    numScroll={2} // Définit combien d'éléments défilent à la fois
    circular
    autoplay={true}
    autoplayInterval={3000}
    transitionInterval={500}
    responsiveOptions={[
      {
        breakpoint: '990px', // Pour les écrans entre 700px et 990px
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '700px', // Pour les écrans de moins de 700px
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '480px', // Pour les très petits écrans comme les téléphones
        numVisible: 1,
        numScroll: 1,
      },
    ]}
    style={{
      width: '100%', // S'assure que le carrousel prend toute la largeur du parent
      padding: '0', // Enlever tout padding supplémentaire
      boxSizing: 'border-box', // Garantir que les paddings sont gérés correctement
    }}
    className="custom-carousel"
  />

  
</div>
<style jsx>{`
  @media (max-width: 768px) {
    
    .custom-carousel {
      margin-top: -5rem;
    }
    .testr {
      width: 100% !important; // Laisser l'élément prendre toute la largeur disponible
      max-width: 100% !important; // Limiter la largeur à 100% du parent
      fontSize: 1rem !important, 
    }
  }
`}</style>
       {/* Section d'assistance */}
       <div
        className="container text-center card pt-4 pb-3 mt-3 mb-4"
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: 'url("/data/applications/pictures/service-consultation.jpeg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
        }}
      >
        <div className="w-100" style={{ marginTop: '7.5%', left: '50%', textAlign: 'center' }}>
        <h3 className="text-light" style={{fontWeight:'bold',color:'#269e4a'}}><span style={{color:'#269e4a'}}>Échanger avec un personnel de santé</span></h3>
          <button onClick={handleRdvClick} className="btn btn-lg mb-5 btn-secondary rounded-1 btn-text-transform-1 text-light">
            Prenez un RDV
          </button>
        </div>
      </div>

      <div id="main" style={{ height: 'auto' }}>
        <div className="bg-light text-dark px-2 py-3 p-lg-4">
          <div className="container">
            <h2 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Notre réseau par ville</h2>
            <h5 className="font-weight-bold" style={{ fontSize: 'medium', fontWeight:'bold', color:'#ffa500', marginLeft: '1.5rem' }}>
            Notre oreille auprès de vous
         </h5>
            <form className="row g-0 align-items-center">
              <div className="col-9 col-lg-10">
                <FloatLabel>
                  <InputText
                    id="partner-search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control form-control-lg"
                    autoComplete="off"
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="partner-search">Rechercher</label>
                </FloatLabel>
              </div>
              <div className="col-3 col-lg-2">
                <button
                  type="submit"
                  className="btn btn-block btn-lg btn-secondary"
                  style={{ marginLeft: '-4px', zIndex: 1, position: 'relative' }}
                  onClick={(e) => {
                    e.preventDefault(); // Empêcher le rechargement de la page
                  }}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="container pt-0 pb-5">
          {loading ? (
            <p>Chargement des partenaires...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            Object.entries(groupedPartenaires).map(([ville, partenairesVille]) => {
              const filteredPartenaires = filterPartenaires(partenairesVille); // Filtrer les partenaires selon le terme de recherche
              return (
                <div key={ville} className="city-section" style={{ marginTop: '2rem',fontWeight:'bold', color:'#ffa500' }}>
                  <h3 className="fs-4 fw-bold mb-3">{ville}</h3>
                  
                  {/* Afficher les 3 premiers partenaires filtrés */}
                  <div className="row g-md-5" style={{ marginTop: '0rem' }}>
                    {filteredPartenaires.slice(0, 3).map((partenaire) => partenaireTemplate(partenaire))}
                  </div>

                  {/* Icône "Voir plus" à l'extrême droite */}
                  {partenairesVille.length > 3 && (
                    <div className="text-end">
                      <Link to={`/ville/${ville}`} className="btn btn-link fs-6 fw-bold">
                        Voir plus <i className="pi pi-angle-right"></i>
                      </Link>
                    </div>
                  )}
                  <hr />
                </div>
              );
            })
          )}
        </div>
        <Footer />
        </div>
     
       
      </div>
     
    </>
  );
};



export default Assistance;

import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';

const HelpPage = () => {
  return (
    <div>
      <NavBar />
      <div style={{ height: '100%' }}>
        {/* Titre principal */}
        <div className="container container-title pt-5 pb-5">
          <div className="apropos d-flex align-items-center" style={{ marginTop: '8rem' }}>
            <h1 className="fs-0 fw-bolder mb-0" style={{ fontWeight: 'bold', color: '#ffa500' }}>Aide et Support - Yoheda</h1>
          </div>
        </div>

        {/* Section des rubriques */}
        <div className="container pt-0 pb-5">
          <h2 className="mb-4" style={{ color: '#28a745' }}>Guide d'utilisation</h2>

          {/* Rubrique Information Sanitaire */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>1. Informations Sanitaires</h3>
            <p>
              Découvrez des informations organisées en plusieurs rubriques pour vous informer sur la santé mentale,
              les maladies infectieuses, et bien plus encore.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-accueil.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>

          {/* Rubrique Assistance */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>2. Assistance</h3>
            <p>
              Besoin d'une écoute active ? Contactez nos numéros verts pour une assistance immédiate.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-assistance.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>

          {/* Rubrique Demande de Consultation */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>3. Demande de Consultation</h3>
            <p>
              Faites une demande de consultation auprès d’un prestataire de santé et suivez le processus
              jusqu'à la validation.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-demande-consultation.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>

          {/* Rubrique Ouverture du Chat */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>4. Ouverture du Chat</h3>
            <p>
              Communiquez avec des prestataires de santé grâce à notre chat intégré.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-discussion.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>

          {/* Rubrique Quizz Bien-être */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>5. Quizz Bien-être</h3>
            <p>
              Évaluez votre état mental grâce à un quizz simple et rapide.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-quizz.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>

          {/* Rubrique Page de Contact */}
          <div className="mb-5">
            <h3 style={{ color: '#ffa500' }}>6. Page de Contact</h3>
            <p>
              Vous avez des questions supplémentaires ou besoin d'aide ? Contactez-nous via notre formulaire de contact.
            </p>
            <video controls width="100%">
              <source src="/assets/videos/yoheda-contact.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </div>
        </div>

        {/* Section Contact */}
        <div className="pt-0 pb-5">
          <div className="col-button w-100 bg-light text-dark px-4 px-lg-0 pt-5 pb-5 text-center">
            <div className="h3 mt-5">Besoin de renseignements ?</div>
            <h6 className="fw-lighter mb-5" style={{ maxWidth: '580px', marginLeft: 'auto', marginRight: 'auto' }}>
              <p>
                Pour en savoir plus sur nos fonctionnalités ou pour demander de l'aide, n’hésitez pas à nous contacter.
                Yoheda Care est là pour vous accompagner.
              </p>
            </h6>
            <Link to="/contact" className="btn btn-lg mb-5 btn-primary rounded-1 btn-text-transform-1 text-light">
              Nous contacter
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpPage;


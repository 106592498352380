import React from 'react';

const PolitiqueDeConfidentialite = () => {
    return (
        <div style={{ padding: "20px", lineHeight: "1.6", fontFamily: "Arial, sans-serif", color: "#333" }}>
            <h1 style={{ textAlign: "center", color: "#007BFF" }}>Politique de Confidentialité</h1>
            <p>
                Bienvenue sur Yoheda Care. La protection de vos données personnelles est une priorité absolue pour nous. 
                Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre plateforme.
            </p>

            <h2>1. Informations collectées</h2>
            <p>Lors de l'utilisation de notre plateforme, nous collectons différents types d'informations :</p>
            <ul>
                <li><strong>Informations personnelles :</strong> nom, prénom, adresse e-mail, numéro de téléphone.</li>
                <li><strong>Données de navigation :</strong> adresse IP, type de navigateur, pages visitées.</li>
                <li><strong>Informations sensibles :</strong> données relatives à votre profil de vulnérabilité (exclusivement avec votre consentement explicite).</li>
            </ul>

            <h2>2. Utilisation des données</h2>
            <p>Les informations collectées sont utilisées dans les buts suivants :</p>
            <ul>
                <li>Fournir les services d’assistance en ligne.</li>
                <li>Assurer la communication entre les utilisateurs via le chat intégré.</li>
                <li>Améliorer l’expérience utilisateur et personnaliser nos services.</li>
                <li>Respecter les obligations légales et réglementaires.</li>
            </ul>

            <h2>3. Partage des données</h2>
            <p>Nous ne partageons vos informations qu'avec les parties suivantes :</p>
            <ul>
                <li><strong>Prestataires de services :</strong> pour l'hébergement, l'envoi d'e-mails ou d'autres services techniques.</li>
                <li><strong>Autorités légales :</strong> uniquement en cas de demande légale ou réglementaire.</li>
            </ul>
            <p>Vos données ne seront jamais vendues à des tiers.</p>

            <h2>4. Sécurité des données</h2>
            <p>Nous utilisons des mesures techniques et organisationnelles pour protéger vos informations :</p>
            <ul>
                <li>Cryptage des données sensibles en transit et au repos.</li>
                <li>Authentification renforcée pour l'accès aux données.</li>
                <li>Audits réguliers de sécurité.</li>
            </ul>

            <h2>5. Conservation des données</h2>
            <p>Vos données personnelles sont conservées aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique ou pour répondre à nos obligations légales.</p>

            <h2>6. Vos droits</h2>
            <p>Vous avez les droits suivants concernant vos données personnelles :</p>
            <ul>
                <li><strong>Accès :</strong> demander une copie des données que nous détenons à votre sujet.</li>
                <li><strong>Rectification :</strong> corriger vos informations personnelles si elles sont incorrectes.</li>
                <li><strong>Suppression :</strong> demander la suppression de vos données, dans les limites prévues par la loi.</li>
                <li><strong>Opposition :</strong> refuser certains traitements spécifiques de vos données.</li>
            </ul>
            <p>Pour exercer vos droits, veuillez nous contacter à <strong>contact@yoheda-care.com</strong>.</p>

            <h2>7. Cookies</h2>
            <p>Nous utilisons des cookies pour :</p>
            <ul>
                <li>Améliorer l'expérience utilisateur.</li>
                <li>Analyser les performances de la plateforme.</li>
                <li>Personnaliser le contenu et les publicités.</li>
            </ul>
            <p>Vous pouvez gérer vos préférences de cookies via les paramètres de votre navigateur.</p>

            <h2>8. Modifications de la politique</h2>
            <p>
                Cette politique de confidentialité peut être mise à jour à tout moment. Toute modification sera communiquée via notre plateforme ou par e-mail. 
                Nous vous encourageons à consulter régulièrement cette page pour rester informé.
            </p>

            <h2>9. Contact</h2>
            <p>
                Si vous avez des questions ou des préoccupations concernant cette politique, veuillez nous contacter à <strong>contact@yoheda-care.com</strong>.
            </p>

            <footer style={{ marginTop: "20px", textAlign: "center", fontSize: "0.9em", color: "#666" }}>
                Dernière mise à jour : 4 décembre 2024
            </footer>
        </div>
    );
};

export default PolitiqueDeConfidentialite;



import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import HeaderAdmin from "../partials/header-admin";
import AdminFooter from "../partials/admin-footer";
import axios from "../utils/axiosConfig";
import "./infoAdd.css"; 
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate } from "react-router-dom";

export default function AdminPage() {
    const [infoSanitaire, setInfoSanitaire] = useState({
        titre: '',
        contenu: '',
        resume: '',
        image: null,
        categoryId: '',
        source: '', // Champ source pour toutes les catégories
        nomFormationSanitaire: ''
    });
    
    const [categories, setCategories] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [newCategory, setNewCategory] = useState({ nom: '', description: '' });
    const toast = useRef(null);
    const token = localStorage.getItem('token'); 
    const [showFormationField, setShowFormationField] = useState(false);
    const navigate = useNavigate();

    // Charger les catégories depuis l'API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('/categories', {
                    headers: {
                        'Authorization': token ? `Bearer ${token}` : ''
                    }
                });
                setCategories(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les catégories' });
            }
        };
        fetchCategories();
    }, [token]);

    // Gestion du changement de catégorie
    const handleCategoryChange = (e) => {
        setInfoSanitaire({ ...infoSanitaire, categoryId: e.value });

        // Afficher le champ de la formation sanitaire uniquement pour "Santé maternelle et infantile"
        if (e.value.nom === 'Actu santé') {
            setShowFormationField(true);
        } else {
            setShowFormationField(false);
        }
    };
    
    // Gestion des changements dans les champs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInfoSanitaire({ ...infoSanitaire, [name]: value });
    };

    const handleImageChange = (e) => {
        setInfoSanitaire({ ...infoSanitaire, image: e.target.files[0] });
    };

    // Envoi du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('titre', infoSanitaire.titre);
        formData.append('contenu', infoSanitaire.contenu);
        formData.append('resume', infoSanitaire.resume);
        formData.append('categoryId', infoSanitaire.categoryId.id);
        
        if (infoSanitaire.image) {
            formData.append('image', infoSanitaire.image);
        }

        if (infoSanitaire.source) {
            formData.append('source', infoSanitaire.source);
        }
        
        if (infoSanitaire.nomFormationSanitaire) {
            formData.append('nomFormationSanitaire', infoSanitaire.nomFormationSanitaire);
        }

        try {
            await axios.post('/informations_sanitaires', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token ? `Bearer ${token}` : ''
                }
            });
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Information sanitaire ajoutée' });
            setTimeout(() => navigate('/admin-info_sanitaire-list'), 2000);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de l\'ajout de l\'information sanitaire' });
        }
    };

    // Ajout d'une nouvelle catégorie
    const handleAddCategory = async () => {
        try {
            const response = await axios.post('/categories', newCategory, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : ''
                }
            });
            setCategories([...categories, response.data]);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Catégorie ajoutée' });
            setShowCategoryModal(false);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de l\'ajout de la catégorie' });
        }
    };

    return (
        <>
            <div className="app-wrapper">
                <HeaderAdmin />
                <main className="app-main">
                    <div className="app-content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3 className="mb-0">Information Sanitaire</h3>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-end">
                                        <li className="breadcrumb-item"><a href="/admin">/admin</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">info_sanitaires</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="app-content">
                        <div className="container-fluid">
                            <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '80%' }}>
                                <div className="container">
                                    <div className="card bg-light text-dark" style={{ borderRadius: '8px',  marginTop:"7rem" }}>
                                        <div className="card-body p-4 p-md-5 text-center">
                                            <form onSubmit={handleSubmit} >
                                                <div className="mb-3">
                                                    <FloatLabel>
                                                        <InputText id="titre" name="titre" value={infoSanitaire.titre} onChange={handleInputChange} className="form-input" required  />
                                                        <label htmlFor="titre" className="form-label">Titre</label>
                                                    </FloatLabel>
                                                </div>
                                                <div className="mb-3">
                                                    <FloatLabel>
                                                        <InputTextarea id="resume" name="resume" value={infoSanitaire.resume} onChange={handleInputChange} className="form-input" rows={20} cols={40} autoResize/>
                                                        <label htmlFor="resume" className="form-label">Résumé</label>
                                                    </FloatLabel>
                                                </div>

                                                <div className="mb-3">
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"10px"}}>
                                                        <FloatLabel>
                                                            <Dropdown id="category" value={infoSanitaire.categoryId} options={categories} optionLabel="nom"  onChange={handleCategoryChange} placeholder="Sélectionner une catégorie" className="w-full md:w-14rem form-input" />
                                                            <label htmlFor="category" className="form-label">Catégorie</label>
                                                        </FloatLabel>
                                                    <Button style={{height:"40px", marginTop:"10px"}} title="Ajouter une nouvelle catégorie" icon="pi pi-plus" className="p-button-secondary" onClick={() => setShowCategoryModal(true)} />
                                                    </div>
                                                </div>

                                                {showFormationField && (
                                                    <div className="mb-3">
                                                        <FloatLabel>
                                                            <InputText 
                                                                id="nomFormationSanitaire" 
                                                                name="nomFormationSanitaire" 
                                                                value={infoSanitaire.nomFormationSanitaire} 
                                                                onChange={handleInputChange} 
                                                                className="form-input" 
                                                                placeholder="Nom de la formation sanitaire (si Santé maternelle et infantile)" 
                                                            />
                                                            <label htmlFor="nomFormationSanitaire" className="form-label">Nom de la formation sanitaire</label>
                                                        </FloatLabel>
                                                    </div>
                                                )}

                                                <div className="mb-3">
                                                    <FloatLabel>
                                                        <InputText 
                                                            id="source" 
                                                            name="source" 
                                                            value={infoSanitaire.source} 
                                                            onChange={handleInputChange} 
                                                            className="form-input" 
                                                            placeholder="Source de l'article" 
                                                        />
                                                        <label htmlFor="source" className="form-label">Source de l'article</label>
                                                    </FloatLabel>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="image" className="form-label">Image</label>
                                                    <FloatLabel>
                                                        <input type="file" id="image" name="image" accept="image/*" onChange={handleImageChange} className="form-input" />
                                                    </FloatLabel>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="contenu" className="form-label">Contenu de l'information sanitaire:</label>
                                                    <Editor value={infoSanitaire.contenu} onTextChange={(e) => setInfoSanitaire({ ...infoSanitaire, ['contenu']: e.htmlValue })} style={{ height: '320px', width:"90%" }} />
                                                </div>

                                                <Button label="Ajouter Information" icon="pi pi-check" type="submit" className="submit-button" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
                <AdminFooter />
            </div>

            <Toast ref={toast} />

            <Dialog header="Ajouter une Catégorie" visible={showCategoryModal} style={{ width: '50vw' }} onHide={() => setShowCategoryModal(false)}>
                <div className="mb-3">
                    <FloatLabel>
                        <InputText id="nom" name="nom" value={newCategory.nom} onChange={(e) => setNewCategory({ ...newCategory, nom: e.target.value })} className="form-input" required />
                        <label htmlFor="nom" className="form-label">Nom de la Catégorie</label>
                    </FloatLabel>
                </div>
                <div className="mb-3">
                    <FloatLabel>
                        <InputText id="description" name="description" value={newCategory.description} onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })} className="form-input" required />
                        <label htmlFor="description" className="form-label">Description</label>
                    </FloatLabel>
                </div>
                <Button label="Ajouter" icon="pi pi-check" onClick={handleAddCategory} />
            </Dialog>
        </>
    );
}

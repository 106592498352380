import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { useNavigate } from 'react-router-dom';
import AdminFooter from '../partials/admin-footer';
import HeaderAdmin from '../partials/header-admin';

const QuestionGroupsPage = () => {
    const [questionGroups, setQuestionGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const navigate = useNavigate(); // Utilisez useNavigate pour la redirection

    useEffect(() => {
        const fetchQuestionGroups = async () => {
            try {
                const response = await axiosInstance.get('/questiongroups/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                
                setQuestionGroups(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement des groupes de questions', life: 3000 });
            } finally {
                setLoading(false);
            }
        };

        fetchQuestionGroups();
    }, []);

    const handleActionClick = async (action, group) => {
        if (action === 'edit') {
            navigate(`/admin-questiongroupupdate/${group.id}/`);
            console.log('Edit', group);
            
            // Implement edit functionality, e.g., navigate to an edit page
        } else if (action === 'addquestion') {
            navigate(`/admin-questionAdd/`);
            console.log('admin-questionAdd');
            
            // Implement edit functionality, e.g., navigate to an edit page
        } else if (action === 'delete') {
            confirmDialog({
                message: 'Êtes-vous sûr de vouloir supprimer ce groupe de questions?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        await axiosInstance.delete(`/questiongroup/${group.id}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                        );
                        setQuestionGroups(questionGroups.filter(g => g.id !== group.id));
                        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Groupe de questions supprimé', life: 3000 });
                    } catch (error) {
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la suppression du groupe de questions', life: 3000 });
                    }
                },
                reject: () => {
                    toast.current.show({ severity: 'info', summary: 'Annulé', detail: 'Suppression annulée', life: 3000 });
                }
            });
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <Dropdown
                options={[
                    { label: 'Modifier', value: 'edit' },
                    { label: 'Supprimer', value: 'delete' },
                    { label: 'Ajouter question', value: 'addquestion' }

                ]}
                onChange={(e) => handleActionClick(e.value, rowData)}
                placeholder="Actions"
                className="p-dropdown"
            />
        );
    };

    return (
        <>
            <div className="app-wrapper">
        <HeaderAdmin />
            <div id="main" style={{ height: '100vh' }}>
                <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '1200px' }}>
                    <div className="container">
                        <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
                            <div className="card-body p-4 p-md-5 text-center">
                                <h1 className="h2 fw-bold mb-4">Groupes de Questions</h1>
                                <DataTable value={questionGroups} loading={loading} paginator rows={10} responsiveLayout="scroll">
                                    <Column field="name" header="Nom" />
                                    <Column field="advice" header="Conseil" />
                                    <Column header="Actions" body={actionTemplate} />
                                </DataTable>
                                <Toast ref={toast} />
                                <ConfirmDialog />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AdminFooter />
            </div>
            
        </>
    );
};

export default QuestionGroupsPage;

import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import "./WellnessQuiz.css";
import { useNavigate, useParams } from "react-router-dom"; // Importation de useParams pour récupérer l'id du quiz
import Footer from "../partials/footer";
import { Dialog } from "primereact/dialog";
import NavBar from "../partials/nav";
import axios from "../utils/axiosConfig"; // Import axios pour faire des appels à l'API

const WellnessQuiz = () => {
  const [messages, setMessages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [questionGroups, setQuestionGroups] = useState([]); 
  const [questionsDisplayed, setQuestionsDisplayed] = useState(new Set()); // Utiliser un Set pour suivre les questions affichées
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { quizId } = useParams();
  const userId = JSON.parse(localStorage.getItem('user_informations'))?.id;

  // Charger les données du quiz depuis l'API
  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(`/quiz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const filteredGroups = response.data.questionGroups.filter(group =>
          group.questions.length > 0 && group.questions.every(q => Array.isArray(q.options) && q.options.length > 0)
        );

        if (response.data.questionGroups.length !== filteredGroups.length) {
          console.warn("Certaines questions ou groupes ne sont pas valides. Elles ont été ignorées.");
        }

        setQuestionGroups(filteredGroups);

        if (filteredGroups.length > 0) {
          const firstQuestion = filteredGroups[0].questions[0];
          setMessages([{ sender: "bot", text: firstQuestion.question }]);
          setQuestionsDisplayed(prev => new Set(prev).add(firstQuestion.id)); // Ajout de l'ID de la question affichée
        }
      } catch (error) {
        console.error("Erreur lors du chargement du quiz", error);
      }
    };
    fetchQuiz();
  }, [quizId]);

  const getCurrentQuestion = () => {
    const group = questionGroups[currentGroupIndex];
    return group?.questions[step] || {};
  };

 
  const Backpage = () => {
    navigate('/quizzList');
  };

  const handleNext = async () => {
    const currentQuestion = getCurrentQuestion();
  
    if (selectedOptions[currentQuestion.id] && selectedOptions[currentQuestion.id].length > 0) {
      // Joindre les réponses en une chaîne séparée par des virgules
      const reponseStr = selectedOptions[currentQuestion.id].join(', ');
  
      setMessages(prevMessages => [
        ...prevMessages,
        { sender: "user", text: reponseStr }
      ]);
  
      try {
        await axios.post(`/quizz/${quizId}/question/${currentQuestion.id}/submit`, {
          utilisateurId: userId,
          reponse: reponseStr, // Envoi des réponses sous forme de chaîne
          questionNom: currentQuestion.question,  
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        // Passer à la question suivante
        if (step < questionGroups[currentGroupIndex].questions.length - 1) {
          setStep(prevStep => prevStep + 1);
          setMessages(prevMessages => [
            ...prevMessages,
            { sender: "bot", text: questionGroups[currentGroupIndex].questions[step + 1].question }
          ]);
        } else {
          const advice = getGroupAdvice(currentGroupIndex);
          setMessages(prevMessages => [
            ...prevMessages,
            { sender: "bot", text: advice }
          ]);
  
          if (currentGroupIndex < questionGroups.length - 1) {
            setCurrentGroupIndex(prevGroupIndex => prevGroupIndex + 1);
            setStep(0);
            setMessages(prevMessages => [
              ...prevMessages,
              { sender: "bot", text: questionGroups[currentGroupIndex + 1].questions[0].question }
            ]);
          } else {
            setQuizFinished(true);
            setMessages(prevMessages => [
              ...prevMessages,
              { sender: "bot", text: <div
        dangerouslySetInnerHTML={{
          __html: `
          <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; padding: 15px;">
          <h3 style="color: #2d89ef; text-align: center; margin-bottom: 20px;">🌟 Passons aux résultats !!! 🌟</h3>
          <p style="font-size: 1.1em;"><strong>Pour les deux (Le niveau d’anxiété et le niveau de dépression) :</strong></p>
          <ul style="list-style-type: none; padding: 0; margin: 15px 0;">
            <li style="margin-bottom: 10px; padding: 10px; background-color: #f4f8fc; border-radius: 5px;">
              <strong>✅ Un score de 7 ou moins :</strong> Absence de symptomatologie, c’est très bien !
            </li>
            <li style="margin-bottom: 10px; padding: 10px; background-color: #fff4e6; border-radius: 5px;">
              <strong>⚠️ Un score de 8 à 10 :</strong> Symptomatologie douteuse, vous êtes à risque.
            </li>
            <li style="margin-bottom: 10px; padding: 10px; background-color: #ffe4e4; border-radius: 5px;">
              <strong>❌ Un score de 11 et plus :</strong> Symptomatologie certaine.
            </li>
          </ul>
          <p style="font-size: 1.1em; font-weight: bold; margin-top: 20px;">📝 NOTEZ QUE :</p>
          <p style="margin-bottom: 20px;">
            La dépression et l'anxiété ne se guérissent pas du jour au lendemain, mais avec des stratégies adaptées, du soutien et de la patience, il est possible de retrouver un bien-être durable. 
            Si vos symptômes sont lourds ou persistants, consultez un professionnel. Une approche médicale, psychologique et de bien-être peut faire une grande différence.
          </p>
          <h4 style="color: #2d89ef; margin-top: 20px;">💡 Voici quelques astuces pour prendre soin de vous :</h4>
          <ul style="padding-left: 20px; margin-top: 10px;">
            <li style="margin-bottom: 10px;">🧘 <strong>Bouger chaque jour :</strong> Même une petite marche aide à améliorer l'humeur.</li>
            <li style="margin-bottom: 10px;">⏸️ <strong>Faire des pauses :</strong> Prenez 5-10 minutes pour vous détendre régulièrement.</li>
            <li style="margin-bottom: 10px;">📵 <strong>Éviter les écrans avant de dormir :</strong> Coupez les écrans 30 minutes avant le coucher.</li>
            <li style="margin-bottom: 10px;">💬 <strong>Parler à quelqu'un :</strong> Exprimez vos émotions avec un ami ou un proche.</li>
            <li style="margin-bottom: 10px;">🌬️ <strong>Respirer profondément :</strong> Prenez 5 minutes pour respirer lentement.</li>
            <li style="margin-bottom: 10px;">🥗 <strong>Manger équilibré :</strong> Fruits, légumes et protéines sont vos alliés.</li>
            <li style="margin-bottom: 10px;">🎯 <strong>Fixer des petits objectifs :</strong> Accomplissez une tâche simple chaque jour.</li>
            <li style="margin-bottom: 10px;">🎨 <strong>Faire ce que vous aimez :</strong> Passez du temps à une activité qui vous détend.</li>
            <li style="margin-bottom: 10px;">😂 <strong>Rire :</strong> Regardez une comédie ou passez du temps avec des amis drôles.</li>
            <li style="margin-bottom: 10px;">🌞 <strong>Passer du temps dehors :</strong> Profitez de la lumière naturelle et de l'air frais.</li>
          </ul>
          <p style="margin-top: 20px; font-size: 1.1em;">💬 <strong>Et rappelez-vous :</strong> Vous n'êtes pas seul(e). La guérison est un chemin, pas une destination instantanée.</p>
          <p style="margin-top: 15px; text-align: center;">
            📘 <strong>Téléchargez ce guide gratuit de l’OMS :</strong> 
            <a href="https://iris.who.int/bitstream/handle/10665/336217/9789240009646-fre.pdf" target="_blank" style="color: #2d89ef; text-decoration: underline;">Cliquez ici pour le guide</a>
          </p>
          <p style="text-align: center; margin-top: 20px; font-size: 1.2em;">❤️ Surtout, prenez soin de vous ! ❤️</p>
        </div>
          `
        }}
      />}
            ]);
          }
        }
  
        // Mise à jour de la barre de progression
        const totalQuestions = questionGroups.flatMap(g => g.questions).length;
        const completedQuestions = questionGroups.slice(0, currentGroupIndex).reduce((sum, g) => sum + g.questions.length, 0) + step + 1;
        setProgress((completedQuestions / totalQuestions) * 100);
        setSelectedOptions({});
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Échec de la soumission de la réponse.",
        });
        console.error("Erreur lors de la soumission de la réponse", error);
      }
    } else {
      toast.current.show({ severity: "warn", summary: "Attention", detail: "Veuillez sélectionner une option avant de continuer." });
    }
  };
  
  const getGroupAdvice = (groupIndex) => {
    const group = questionGroups[groupIndex];
    return group.advice;
  };

  const handleOptionSelect = (option) => {
    const currentQuestion = getCurrentQuestion();
    const selected = selectedOptions[currentQuestion.id] || [];
    if (selected.includes(option)) {
      // Si l'option est déjà sélectionnée, on la retire
      setSelectedOptions({
        ...selectedOptions,
        [currentQuestion.id]: selected.filter(opt => opt !== option)
      });
    } else {
      // Si l'option n'est pas encore sélectionnée, on l'ajoute
      setSelectedOptions({
        ...selectedOptions,
        [currentQuestion.id]: [...selected, option]
      });
    }
  };

  const renderOptions = () => {
    const currentQuestion = getCurrentQuestion();

    if (!currentQuestion.options || !Array.isArray(currentQuestion.options) || currentQuestion.options.length === 0) {
      console.error("La question n'a pas d'options valides :", currentQuestion);
      return <p className="error-message">Cette question n'a pas d'options disponibles.</p>;
    }

    return currentQuestion.options.map((option, index) => (
      <div key={index} className="option-item">
        <label>
          <input
            type={currentQuestion.type} // 'checkbox' ou 'radio' en fonction du type de question
            name={currentQuestion.id}
            value={option}
            checked={selectedOptions[currentQuestion.id]?.includes(option)} // On vérifie si l'option est sélectionnée
            onChange={() => handleOptionSelect(option)}
          />
          {option}
        </label>
      </div>
    ));
  };

  const handleFinish = () => {
    setShowSuccessModal(true);

    setTimeout(() => {
      setShowSuccessModal(false);
      navigate('/quizzList');
    }, 5000);
  };

  const renderMessages = () => {
    return messages.map((msg, index) => (
      <div key={index} className={`message ${msg.sender === "bot" ? "bot-message" : "user-message"}`}>
        <div className="message-content">
          <p>{msg.text}</p>
        </div>
      </div>
    ));
  };

  return (
    <>
      <NavBar />
      <div className="quiz-container">
        <Toast ref={toast} />
        <div className="return-button">
          <Button
            icon="pi pi-arrow-left"
            className="p-button-rounded p-button-text"
            onClick={() => Backpage()}
          />
        </div>
        <div className="p-col-12 w-100">
          <Card
            className="chat-card w-75 apropos"
            style={{
              margin: '0 auto', // Centré pour tous les écrans
              marginTop: '10rem', // Espacement vertical
            }}
            header={<div className="card-header-style" style={{ fontWeight: 'bold', color: '#ffa500' }}>Évaluation de bien-être</div>}
          >
            <div className="chat-box">
            <div
              style={{
                fontFamily: "Arial, sans-serif",
                color: "#333",
                backgroundColor: "#f9f9f9",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                lineHeight: "1.6",
                maxWidth: "600px",
                marginTop: "1rem",
                marginBottom: "1.5rem",
                                
            
                textAlign: "center"
              }}
            >
              <h3 style={{ color: "#2d89ef", fontSize: "1.8em", marginBottom: "10px" }}>👋 Bonjour !</h3>
              <p style={{ fontSize: "1.2em", marginBottom: "15px" }}>
                Nous vous proposons d’évaluer votre niveau d’anxiété et de dépression.
              </p>
              <p style={{ marginBottom: "15px" }}>
                Nous allons utiliser <strong>l’Hospital Anxiety and Depression scale</strong>, un instrument permettant de dépister les troubles anxieux et dépressifs.
              </p>
              <p style={{ marginBottom: "15px" }}>
                <strong>C’est très simple :</strong> Vous aurez <strong>14 questions</strong> auxquelles vous devrez répondre. Pour chaque question, notez le numéro de la question et le chiffre correspondant à votre réponse.
              </p>
              <p style={{ fontStyle: "italic", fontSize: "1.1em", marginBottom: "20px" }}>
                Tout d’abord, nous vous prions de répondre à <strong>3 petites questions</strong> pour mieux nous situer…
              </p>
            </div>
              {renderMessages()}
              {!quizFinished && (
                <div className="options-container">
                  {renderOptions()}
                </div>
              )}
            </div>
            <ProgressBar value={progress} className="p-mb-3" />
            <div className="input-container">
              {!quizFinished ? (
                <Button
                  label="Suivant"
                  icon="pi pi-check"
                  onClick={handleNext}
                  className="p-mt-3"
                  style={{backgroundColor:'#c8a931', marginTop:'2rem'}}
                />
              ) : (
                <Button
                  label="Terminer"
                  icon="pi pi-check"
                  onClick={handleFinish}
                  className="p-mt-3"
                  style={{ marginTop:'2rem'}}
                />
              )}
            </div>
          </Card>
        </div>
        {showSuccessModal && (
          <Dialog
            visible={showSuccessModal}
            onHide={() => setShowSuccessModal(false)}
            header="Félicitations"
            modal
            footer={
              <Button
                label="Fermer"
                icon="pi pi-check"
                onClick={() => setShowSuccessModal(false)}
              />
            }
          >
            <p>Vous avez terminé le quiz avec succès !</p>
          </Dialog>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WellnessQuiz;

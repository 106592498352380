import React, { useState, useEffect, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import './notifications.css';
import axios, { frontendUrl } from './axiosConfig';
import { Link, useLocation, useNavigate } from'react-router-dom';
const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    
    const [unreadCount, setUnreadCount] = useState(0);
    const notificationRef = useRef(null);
    const navigate = useNavigate();

    // Fonction pour envoyer un message au service worker
    const sendMessageToServiceWorker = (message) => {
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(message);
      }
    };

    // Fonction pour marquer une notification comme lue
    const markAsRead = (notificationId) => {
      sendMessageToServiceWorker({ type: 'mark_as_read', data: { id: notificationId } });
    };

    useEffect(() => {
        // Récupérer le nombre de notifications non lues
        const fetchUnreadCount = async () => {
          try {
            const response = await axios.get('/api/notifications/unread',  {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              });
            setUnreadCount(response.data.unreadCount);
          } catch (error) {
            console.error("Erreur lors de la récupération des notifications non lues", error);
          }
        };
    
        fetchUnreadCount();
      }, []);

    useEffect(() => {

        const fectNotifications = async ()=>{
            try {
              const response = await axios.get(`/notifications/page/1`, 
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }
              ).catch((err)=>{
                console.error(err);
                if (err?.response?.statusCode === 403 ){
                  alert("Session expirée");
                  navigate("/login")
                }
              });
              setNotifications(response.data);
              
            } catch (error) {
              console.error(error);
            }
          };
      
          if(localStorage.getItem('token')) {
            fectNotifications();
          }

        
    }, []);

    // Réception des messages du service worker
    useEffect(() => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          const { type, data } = event.data;

          if (type === 'ws_new_notif') {
            // Mettre à jour les notifications
            const newNotification = data.notifications;
            setNotifications((prevNotifications) => {
              // Vérifie que prevNotifications est un tableau
              const notificationsArray = Array.isArray(prevNotifications) ? prevNotifications : [];
              return [newNotification, ...notificationsArray, ];
            });

            // Mettre à jour le nombre de notifications non lues
            setUnreadCount((prevCount) => prevCount + 1);

            // envoyer une notification au bureau
            if (Notification.permission === "granted") {
              const notif = new Notification(newNotification.title, {
                body: newNotification.contenu,
                icon: `${frontendUrl}/favicon.ico`, // Icône facultative
              });
                // Ajouter un son à la notification
              const notificationSound = new Audio(`${frontendUrl}/data/applications/audio/mixkit-bell-notification-933.mp3`); // Chemin du fichier audio
              notificationSound.play();
              notif.onclick = function(event) {
                event.preventDefault(); // Empêche le comportement par défaut
                window.open(`${frontendUrl}`, '_self'); // Remplacez 'URL_DE_VOTRE_APPLICATION' par l'URL de votre application
            };
            }
          }
        });
      }
    }, []);

    const markAllAsRead = async(e)=>{
        e.preventDefault();
        try {
            await axios.put('/api/notifications/mark-all-as-read', null, 
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }
            );
            setUnreadCount(0);
        } catch (error) {
            console.error(error);
        }
    }
    const badgeStyle = {
      position: "absolute",
      cursor: 'pointer',
      top: "10px", // Positionnement par défaut
      right: "10px", // Ajuster selon votre design
      transform: "translate(50%, -50%)", // Centrage sur l'icône
  };
    return (
        <div >
                  <div style={{ position: "relative", display: "inline-block" }}>
            <Button
                icon="pi pi-bell"
                rounded
                text
                severity="warning"
                aria-label="Notification"
                onClick={(e) => notificationRef.current?.toggle(e)}
                badgeClassName="p-badge-danger"
            />
            {unreadCount > 0 && (
                <Badge
                    value={unreadCount.toString()}
                    severity="danger"
                    style={badgeStyle}
                    onClick={(e) => notificationRef.current?.toggle(e)}
                />
            )}
        </div>
            
                    <OverlayPanel className="p-overlaypanel p-shadow-3" style={{marginTop:"3.5rem", zIndex:10000,  width:"300px"}} ref={notificationRef}>
                      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                          <div className="p-text-bold p-mb-2">Notifications</div>
                          <Button icon="pi pi-check-circle" rounded outlined severity='secondary' onClick={markAllAsRead} aria-label='Mark-all-as-read'/>
                      </div>
                      <ul className="notification-list" style={{ maxHeight: "300px", overflowY: "auto" }}>
                          {notifications.length > 0 ? notifications.map((notification, index) => (
                              <li key={index} className="notification-item p-d-flex p-ai-center" style={{ padding: "0.5rem 0", backgroundColor: notification.lu ? "initial" : "aliceblue"  }}>
                                  {notification.image ? (
                                      <Avatar image={notification.image} shape="circle" style={{marginRight:"0.8rem"}} />
                                  ) : (
                                      <i style={{marginRight:"0.8rem"}} className="pi pi-bell p-mr-2 p-text-secondary"></i>
                                  )}
                                  <div className="p-d-flex p-flex-column">
                                      <div className="p-text-bold">{notification.title}</div>
                                      <small className="p-text-sm">{notification.contenu}</small>
                                      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                          <small>{new Date(notification.createdAt).toLocaleString("fr")}</small>
                                          <Link to={notification.action} className="p-text-secondary">Voir plus</Link>
                                      </div>
                                  </div>
                              </li>
                          )) : (
                              <h4>Pas de notifications pour le moment</h4>
                          )}
                      </ul>
                  </OverlayPanel>

        </div>
    );
};

export default Notifications;

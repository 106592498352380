import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Notifications from '../utils/notifications';
import UnreadMessages from '../utils/unreadMessage';
import { baseURL } from '../utils/axiosConfig';

// Check if the user is logged in and get user information
const getUserInfo = () => {
  const userInformations = localStorage.getItem('user_informations');
  if (!userInformations) return null;
  return JSON.parse(userInformations);
};

const isDoctorLoggedIn = () => {
  const user = getUserInfo();
  return user && user.role === 'Medecin';
};

const NavBar = () => {
  const [activeLink, setActiveLink] = useState('/');
  const [showAppointmentButton, setShowAppointmentButton] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Reference for the dropdown to handle outside click

  useEffect(() => {
    setShowAppointmentButton(isDoctorLoggedIn());
    const user = getUserInfo();
    if (user) {
      setUserInfo(user);
    }
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const toggleDropdown = () => {
    if (userInfo) {
      setShowDropdown(!showDropdown);
    } else {
      navigate('/login');
    }
  };

    // Récupérer l'ID du médecin depuis le localStorage
    const userData = JSON.parse(localStorage.getItem('user_informations')); // Assurez-vous que les données existent
    const userid = userData ? userData.id : null; // Si l'objet est valide, prenez l'ID

  const handleLogout = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (let registration of registrations) {
              registration.unregister().then(function() {
                  console.log('Service worker désinstallé.');
              }).catch(function(error) {
                  console.error('Erreur lors de la désinstallation du service worker:', error);
              });
          }
      }).catch(function(error) {
          console.error('Erreur lors de la récupération des enregistrements du service worker:', error);
      });
    }
    localStorage.removeItem('user_informations');
    localStorage.removeItem('token');
    localStorage.removeItem('admin_user_inf');
    setUserInfo(null);
    navigate('/login');
  };

  const handleEdit = () => {
    navigate(`/userudapte/${userid}`);
  };

  const handleProfil = () => {
    navigate(`/user/${userid}`);
  };
    useEffect(() => {
      if ('serviceWorker' in navigator) {
        // Ajouter un listener pour écouter les messages du Service Worker
        navigator.serviceWorker.addEventListener('message', (event) => {
            const { type, data } = event.data;

            if (type === 'ws_status') {
                console.log('WebSocket status:', event.data);
                // Mettre à jour l'interface utilisateur en fonction de l'état WebSocket
            } else if (type === 'ws_message') {
                console.log('Message from WebSocket:', data);
                // Gérer les notifications ou les données du backend ici
                // Par exemple, afficher une notification ou mettre à jour l'interface utilisateur
            }
        });
      }

      return () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.removeEventListener('message', () => {});
        }
    };
    }, []);
  return (
    <>
     {/* header  */}
     <header style={{position:"fixed", width:"100%", zIndex:"1000"}}>
         {/* Navbar for contact information */}
          <nav className="navbar navbar-expand-lg sticky-top navbar-light" style={{ backgroundColor: '#e3f2fd', padding: '0.5rem 0' }}>
            <div className="container">
              <div className="d-flex justify-content-start w-100">
                <span style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'rgba(7, 53, 21, 0.67)' }}>
                  Contactez-nous à travers ce numéro vert ou par WhatsApp : <a href="tel:0800123456">+237687113955 ou 1510</a>
                </span>
              </div>
            </div>
          </nav>

          {/* Main Navbar */}
          <nav className="navbar navbar-expand-lg sticky-top navbar-light" style={{ backgroundColor: '#306941' }} data-mdb-color="light" id="navbar-top-desktop">
            <div className="container">
              <Link className="navbar-brand navbar-brand-light my-2 my-lg-0 py-lg-1" style={{ borderRadius: '13%' }} to="/" data-ca-link-id="">
                <img src="/data/applications/logos/11090/hd/4b544732833a213a2d21f318f490b2e0.png" alt="Conseils de Plantes" className="me-2" />
              </Link>
              <div className="collapse navbar-collapse" id="navbar-desktop" style={{ backgroundColor: '#306941' }}>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/' ? 'active' : ''}`} to="/" onClick={() => handleLinkClick('/')}>
                      Accueil
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/healthInfo' ? 'active' : ''}`} to="/healthInfo" onClick={() => handleLinkClick('/healthInfo')}>
                      Informations Sanitaires
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/assistance' ? 'active' : ''}`} to="/assistance" onClick={() => handleLinkClick('/assistance')}>
                      Assistance
                    </Link>
                  </li>
                  {showAppointmentButton && (
                    <li className="nav-item">
                      <Link className={`nav-link ${activeLink === '/appointments' ? 'active' : ''}`} to="/appointments" onClick={() => handleLinkClick('/appointments')}>
                        Rendez-vous
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/faq' ? 'active' : ''}`} to="/faq" onClick={() => handleLinkClick('/faq')}>
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/quizzList' ? 'active' : ''}`} to='/quizzList' onClick={() => handleLinkClick('/quizzList')}>
                      Bien-être
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/about' ? 'active' : ''}`} to="/about" onClick={() => handleLinkClick('/about')}>
                      À propos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === '/help' ? 'active' : ''}`} to="/help" onClick={() => handleLinkClick('/help')}>
                      Aide
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                
                <UnreadMessages />

                <Notifications />

                {/* User Profile Icon and Dropdown */}
                <div className="position-relative" ref={dropdownRef}>
                  <span style={{cursor:"pointer", display:"grid", fontWeight:"bold", color:"orange" , }} className="rounded-circle d-inline-block fs-5 avatar me-2" onClick={toggleDropdown}>
                    {userInfo?.photo ? (
                      <img src={baseURL + userInfo.photo} alt="Profile" className="rounded-circle" style={{ width: '40px', height: '40px', marginBottom:'2mm'}} />
                    ) : (
                      <i className="fas fa-user-alt" style={{ marginBottom:'2mm'}}></i>
                    )}
                    Profil
                  </span>
                  {showDropdown && (
                    <div className="dropdown-menu show position-absolute end-0 mt-2" style={{ width: '3px' }}>
                      <span className="dropdown-item-text" style={{ fontWeight: 'bold' }}>Nom : {userInfo?.nom}</span>
                      <button className="dropdown-item" style={{ background: '#306941', color: '#ffff', fontWeight: 'bold' }} onClick={handleProfil}>Profil</button>
                      <button className="dropdown-item" style={{ background: '#306941', color: '#ffff', fontWeight: 'bold' }} onClick={handleEdit}>Editer</button>
                      <button className="dropdown-item" style={{ background: '#306941', color: '#ffff', fontWeight: 'bold' }} onClick={()=>navigate(`/discussion/${userInfo.id}`)}> Chat </button>
                      <button className="dropdown-item" style={{ background: '#306941', color: '#ffff', fontWeight: 'bold' }} onClick={handleLogout}>Se déconnecter</button>
                    </div>
                  )}
                </div>

                <button
                  id="nav-toggle-btn"
                  data-mdb-toggle="sidenav"
                  data-mdb-target="#sidenav-1"
                  className="navbar-toggler text-light d-block"
                  aria-controls="sidenav-1"
                  aria-haspopup="true"
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>
            </div>
          </nav>
     </header>

      {/* Sidenav */}
      <nav id="sidenav-1" className="sidenav bg-tertiary" data-mdb-hidden="true" data-mdb-right="true" data-mdb-color="light">
        <div className="text-center mb-3">
          <h3 className="py-4 px-2 fs-5 mb-0">YOHEDACARE</h3>
          <hr className="m-0" />
        </div>
        <ul className="sidenav-menu mb-3">
          <li className="sidenav-item">
            <Link className="sidenav-link active" data-ca-link-id="" to="/">
              <i className="fas fa-home me-3 text-light"></i>
              <span>Accueil</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <Link className="sidenav-link" data-ca-link-id="blog/57469" to="/healthInfo">
              <i className="fas fa-book me-3 text-light"></i>
              <span>Informations Sanitaires</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <Link className="sidenav-link" to="/assistance">
              <i className="fas fa-play-circle me-3 text-light"></i>
              <span>Assistance</span>
            </Link>
          </li>
          {showAppointmentButton && (
            <li className="sidenav-item">
              <Link className="sidenav-link" to="/appointments">
                <i className="fas fa-play-circle me-3 text-light"></i>
                <span>Rendez-vous</span>
              </Link>
            </li>
          )}
          <li className="sidenav-item">
            <Link className="sidenav-link" to="/faq">
              <i className="fas fa-shopping-basket me-3 text-light"></i>
              <span>FAQ</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <Link className="sidenav-link" data-ca-link-id="contact/57472" to="/quizzList">
              <i className="fas fa-envelope me-3 text-light"></i>
              <span>Bien-être</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <Link className="sidenav-link" to="/about">
              <i className="fas fa-shield-alt me-3 text-light"></i>
              <span>À propos</span>
            </Link>
          </li>
        </ul>
        <div className="mb-2" id="navbarMenuAvatar">
          <hr className="mt-0 mb-2" />
          <ul className="sidenav-menu">
            <li className="sidenav-item">
              <Link to="/login" className="sidenav-link">
                <span className="rounded-circle d-inline-block fs-5 avatar me-2">
                  <i className="fas fa-user-alt"></i>
                </span>
                <span>Se connecter</span>
              </Link>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="sidenav-menu">
          <li className="sidenav-item">
            <Link to="/politique-de-confidentialite" className="sidenav-link fs-8 fw-lighter py-2" style={{ height: 'auto' }}>
              <span>Politique de confidentialité</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <Link to="/help" className="sidenav-link fs-8 fw-lighter py-2" style={{ height: 'auto' }}>
              <span>Aide</span>
            </Link>
          </li>
          <li className="sidenav-item">
            <span className="sidenav-link fs-8 fw-lighter py-2" id="open-preferences-cookies" style={{ height: 'auto' }}>
              <span>Cookies</span>
            </span>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;




import {BrowserRouter, Routes, Route } from 'react-router-dom'

//import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import HealthInfo from './pages/healthInfo';
import HealthDetail from './pages/healthDetail';
import Faq from './pages/faq';
import Page404 from './pages/404';
import RDV from './pages/rdv';
import Contact from './pages/contact';
import EmergencyNumbers from './pages/emergencyNumbers';
import About from './pages/about';
import Login from './pages/login';
import Register from './pages/register';
import ChatPage from './pages/discussions';
import Assistance from './pages/assistance';
import Appointments from './pages/Appointments';
import WellnessQuiz from './pages/WellnessQuiz';
import AdminPage from './admin';
import InfoSnaitaireAdd from './admin/info_sanitaire_add'
import InfoListPage from './admin/info_sanitaires_list';
import PrivateRoute from './partials/PrivateRoute';
import AcceptedAppointments from './pages/AcceptedAppointments';
import UserDetails from './pages/UserDetails';
import UserUpdate from './pages/UserUpdate';
import AddQuestionForm from './admin/quizz_bien_etre_addQuestions';
import AddQuestionGroupForm from './admin/quizz_bien_etre_addQuestionsgroupe';
import QuestionsPage from './admin/QuestionsPage';
import QuestionGroupsPage from './admin/QuestionGroupsPage';
import UpdateQuestionPage from './admin/UpdateQuestionPage';
import UpdateQuestionGroupPage from './admin/UpdateQuestionGroupPage';
import QuizList from './pages/QuizList';
import PartnersPage from './admin/PartnersPage';
import CreatePartner from './admin/CreatePartner';
import UpdatePartner from './admin/UpdatePartner';
import CategoryPage from './pages/CategoryPage';
import PartenairesVille from './pages/PartenairesVille';
import RegisterMed from './admin/medecin';


import 'primereact/resources/themes/saga-blue/theme.css';  // ou un autre thème de votre choix
import 'primereact/resources/primereact.min.css';          // Styles de base
import 'primeicons/primeicons.css';                        // Icônes
import AdminMedecins from './admin/medecin-list';
import InfoSanitaireEdit from './admin/info_sanitaire_edit';
import PartenairesVilleInfo from './pages/PartenairesVilleinfo';
import StatisticsComponent from './admin/StatisticsComponent';
import ResetRequest from './pages/ResetRequest';
import ResetPassword from './pages/ResetPassword';
import QuizzesPage from './admin/QuizzesPage';
import RendezVousNonTraites from './admin/rdv-non-traiter';
import FeedbackForm from './pages/RDVFeedbackForm';
import Page from './pages/feedbacks';
import PolitiqueDeConfidentialite from './pages/politqueDeConfidentialite'; 
import HelpPage from './pages/help';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/healthInfo' element={<HealthInfo/>} />
            <Route path="/healthDetail/:id" element={<HealthDetail />} />
            <Route path='faq' element={<Faq />} />
            <Route path='/rdv' element={<PrivateRoute><RDV /></PrivateRoute>} />
            <Route path='/contact' element={<Contact />} />
            <Route path='emergency-numbers' element={<EmergencyNumbers />} />
            <Route path='/about' element={<About />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/discussion/:id' element={<PrivateRoute><ChatPage /></PrivateRoute>} />
            <Route path='/discussion' element={<PrivateRoute><ChatPage /></PrivateRoute>} />
            <Route path='/acceptedAppointments' element={ <PrivateRoute><AcceptedAppointments/></PrivateRoute>} />
            <Route path='/appointments' element={ <PrivateRoute><Appointments/></PrivateRoute>} />
            <Route path='/admin' element={<AdminPage />} />
            <Route path="/quiz/:quizId/" element={ <PrivateRoute> <WellnessQuiz /> </PrivateRoute> }/> 
            <Route path="/assistance" element={  <Assistance /> }/>
            <Route path='/admin-info_sanitaire-ajout' element={<InfoSnaitaireAdd />}/>
            <Route path='/admin-info_sanitaire-list' element={<InfoListPage />} />
            <Route path='/userudapte/:id/' element={<PrivateRoute><UserUpdate /></PrivateRoute>} />
            <Route path="/user/:id/" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
            <Route path='/admin-questionAdd' element={<PrivateRoute><AddQuestionForm /></PrivateRoute>} />
            <Route path='/admin-questionGroupAdd' element={<PrivateRoute><AddQuestionGroupForm /></PrivateRoute>} />
            <Route path="/admin-questions" element={<PrivateRoute><QuestionsPage /></PrivateRoute>} />
            <Route path="/admin-questionupdate/:id" element={<PrivateRoute><UpdateQuestionPage /></PrivateRoute>} />
            <Route path="/admin-questiongroups" element={<PrivateRoute><QuestionGroupsPage /></PrivateRoute>} />
            <Route path="/admin-questiongroupupdate/:id" element={<PrivateRoute><UpdateQuestionGroupPage /></PrivateRoute>} />
            <Route path="/quizzList" element={<PrivateRoute><QuizList /></PrivateRoute>} />
            <Route path="/admin-partners" element={<PrivateRoute><PartnersPage /></PrivateRoute>} />
            <Route path="/admin-partnerAdd" element={<PrivateRoute><CreatePartner /></PrivateRoute>} />
            <Route path="/admin-quizzList_admin" element={<PrivateRoute><QuizzesPage /></PrivateRoute>} /> 
            <Route path="/admin-partnerUpdate/:id/" element={<PrivateRoute><UpdatePartner /></PrivateRoute>} /> 
            <Route path="/ville/:ville" element={<PartenairesVille />} />
            <Route path="/villeinfo/:ville" element={<PartenairesVilleInfo />} />
            <Route path='/categories/:categoryId/articles' element={<CategoryPage />} />
            <Route path='/admin-add-medecin/' element={<RegisterMed />} />
            <Route path='/admin-medecins-list' element={<PrivateRoute><AdminMedecins /></PrivateRoute>} />
            <Route path='/admin-infosanitaire-editer/:id' element={<PrivateRoute><InfoSanitaireEdit /></PrivateRoute>} />
            <Route path='/admin-statique_questions' element={<PrivateRoute><StatisticsComponent /></PrivateRoute>} />
            <Route path="/forgot-password" element={<ResetRequest />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/rdv-non-traites" element={<RendezVousNonTraites />} />
            <Route path='/feedback/:id' element={<FeedbackForm />} />
            <Route path='/feedback' element={<Page />} />
            <Route path='/politique-de-confidentialite' element={<PolitiqueDeConfidentialite />} />
            <Route path='/help' element={<HelpPage />} />
            <Route path='*' element={<Page404 />} />
            </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;

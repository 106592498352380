import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const AddQuestionGroupForm = () => {
  const [name, setName] = useState('');
  const [advice, setAdvice] = useState('');
  const [quizzName, setQuizzName] = useState(''); // Pour le nom du quizz
  const [selectedQuizz, setSelectedQuizz] = useState(null); // Pour le dropdown
  const [quizzList, setQuizzList] = useState([]); // Pour stocker la liste des quizz
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  // Charger la liste des quizz pour le dropdown
  const fetchQuizzList = async () => {
    try {
      const response = await axiosInstance.get('/quizz/', 
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setQuizzList(response.data.map(quizz => ({ label: quizz.name, value: quizz.id })));
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de chargement des quizz', life: 3000 });
    }
  };

  useEffect(() => {
    fetchQuizzList();
  }, []);

  const handleQuizzSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.post('/quizz/',
      { name: quizzName } ,
      {
        headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Quizz ajouté', life: 3000 });
      setQuizzName('');
      // Mettre à jour la liste des quizz après l'ajout
      fetchQuizzList();
      // setTimeout(() => navigate('/questiongroup'), 2000);
       // Redirection vers la liste des groupes de questions
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de l\'ajout du quizz', life: 3000 });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newGroup = {
      name,
      advice,
      QuizzId: selectedQuizz, // Lier le groupe au quizz sélectionné
    };

    try {
      await axiosInstance.post('/questiongroup/',newGroup,
      {
        headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }, );
      toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Groupe de questions ajouté', life: 3000 });
      setName('');
      setAdvice('');
      setSelectedQuizz(null); // Réinitialiser la sélection du quizz
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de l\'ajout du groupe', life: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
         <div className="app-wrapper">
        <HeaderAdmin />
      <div id="main" style={{ height: '100vh' }}>
        <Toast ref={toast} />
        <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '600px' }}>
          <div className="container">
            {/* Formulaire pour ajouter un Quizz */}
            <div className="card bg-light text-dark mb-4" style={{ borderRadius: '8px' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <h1 className="h2 fw-bold mb-4">Ajouter un Quizz</h1>
                <form onSubmit={handleQuizzSubmit}>
                  <div className="mb-4">
                    <InputText
                      id="quizzName"
                      value={quizzName}
                      onChange={(e) => setQuizzName(e.target.value)}
                      required
                      className="form-control form-control-lg"
                      placeholder="Nom du Quizz"
                    />
                  </div>
                  {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                  <Button
                    type="submit"
                    label="Ajouter Quizz"
                    icon="pi pi-check"
                    className="p-button p-button-primary p-button-lg w-100"
                    disabled={loading}
                  />
                </form>
              </div>
            </div>

            {/* Formulaire pour ajouter un Groupe de Questions */}
            <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <h1 className="h2 fw-bold mb-4">Ajouter un groupe de questions</h1>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <InputText
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="form-control form-control-lg"
                      placeholder="Nom du groupe"
                    />
                  </div>
                  <div className="mb-4">
                    <Editor
                      value={advice}
                      onTextChange={(e) => setAdvice(e.htmlValue)}
                      style={{
                        height: '320px',
                        border: '1px solid #ced4da',
                        borderRadius: '5px',
                        padding: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                      }}
                      placeholder="Conseil à afficher après les réponses d'un groupe (optionnel)"
                    />
                  </div>

                  <div className="mb-4">
                    <Dropdown
                      value={selectedQuizz}
                      options={quizzList}
                      onChange={(e) => setSelectedQuizz(e.value)}
                      placeholder="Sélectionner un Quizz"
                      className="w-100"
                      required
                    />
                  </div>
                  {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                  <Button
                    type="submit"
                    label="Ajouter Groupe"
                    icon="pi pi-check"
                    className="p-button p-button-primary p-button-lg w-100"
                    disabled={loading}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdminFooter />
      </div>
    </>
  );
};

export default AddQuestionGroupForm;

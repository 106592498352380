import React, { useState, useEffect } from 'react';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Link, useParams } from 'react-router-dom';
import axios, { baseURL } from './../utils/axiosConfig';

const PartenairesVilleInfo = () => {
    const { ville: id } = useParams(); // Récupérer l'ID du partenaire depuis les paramètres d'URL
    const [partenaire, setPartenaire] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Récupérer les données du partenaire spécifique via son ID
    useEffect(() => {
        const fetchPartenaire = async () => {
            try {
                const response = await axios.get(`/partenaires/${id}`,
                {headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }});
                if (response.data) {
                    setPartenaire(response.data); // Charger le partenaire trouvé
                } else {
                    setError("Aucun partenaire trouvé pour cet ID.");
                }
                setLoading(false);
            } catch (err) {
                setError("Une erreur s'est produite lors du chargement des données.");
                setLoading(false);
            }
        };

        fetchPartenaire();
    }, [id]);

    if (loading) {
        return <div>Chargement de l'association...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!partenaire) {
        return <div>Aucun partenaire trouvé pour l'ID : {id}</div>;
    }

    return (
        <>
            <NavBar />
            <div id="main" style={{ height: '100%' }}>
                <div className="container mb-3 mt-3 pt-2">
                    <div className="d-flex align-items-center mt-4 mb-5">
                        <Link to="/assistance" className="pe-3 d-inline-block" style={{ marginTop: '6.5rem' }}>
                            <i className="fas fa-arrow-left fs-6" style={{ fontWeight: 'bold', color: '#ffa500' }}></i>
                        </Link>
                        <h1 className="fw-bolder h2 mb-0" style={{ fontWeight: 'bold', color: '#ffa500', marginTop: '6.5rem' }}>
                            {partenaire.nom}
                        </h1>
                    </div>
                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{ color: "#249646", fontWeight: "bold" }}>
                        Association
                    </h2>

                    <div className="bg-image mb-4">
                        <img
                            src={`${baseURL}/${partenaire.photo}`} 
                            alt={partenaire.nom}
                            title={partenaire.nom}
                            className="w-100 rounded img-fluid"
                        />
                    </div>

                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{ color: "#249646", fontWeight: "bold" }}>
                        Leader: {partenaire.representant}
                    </h2>
                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{ color: "#249646", fontWeight: "bold" }}>
                        Numéro: {partenaire.numero}
                    </h2>

                    <div className="py-2 py-lg-4">
                        <div dangerouslySetInnerHTML={{ __html: partenaire.description }}></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PartenairesVilleInfo;

import React, { useState, useEffect } from 'react';
import { Badge } from 'primereact/badge';
import axios, { baseURL, frontendUrl } from './axiosConfig';
import { Link } from 'react-router-dom';

const UnreadMessages = () => {
    const [unreadCount, setUnreadCount] = useState(0);


    useEffect(() => {
            // Fonction pour récupérer le nombre de messages non lus
    const fetchUnreadMessages = async () => {
        try {
            const response = await axios.get('/api/messages/unread', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages non lus', error);
        }
    };

        // Récupérer les messages non lus lors du montage
        fetchUnreadMessages();

        // Écouter les messages du service worker
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                const { type, data } = event.data;
                if (type === 'ws_message') {
                    const wsData = data;
                    
                    if (wsData.type === "new_message") {
                        // Mettre à jour le nombre de messages non lus
                        setUnreadCount((prevCount) => prevCount + 1);

                        // verfier si on n'est pas dans la page de discussion avant d''envoyer la notification au bureau du client
                        if (!window.location.pathname.includes('discussion')) {
                            if (Notification.permission === "granted") {
                                console.log(wsData.message);
                                
                                const notification = new Notification(`${wsData.message.Utilisateur?.nom} ${wsData.message.Utilisateur?.prenom}`, {
                                    body: wsData.message.contenu,
                                    icon: `${baseURL}${wsData.message.Utilisateur?.photo}`, // Icône facultative
                                });
                                // Ajouter un son à la notification
                                const notificationSound = new Audio(`${frontendUrl}/data/applications/audio/mixkit-bell-notification-933.mp3`); // Chemin du fichier audio
                                notificationSound.play()
                                notification.onclick = function(event) {
                                    event.preventDefault(); // Empêche le comportement par défaut
                                    window.open(`${frontendUrl}/discussion/${wsData.message.ChatRoomId}`, '_self'); // Remplacez 'URL_DE_VOTRE_APPLICATION' par l'URL de votre application
                                };
                            }
                        }
                    } else if (type === 'ws_message_read') {
                        // Mettre à jour le nombre après lecture d'un message
                        setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
                    }
                }
            });
        }
    }, []);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <Link to="/discussion">
                <i className="pi pi-comment" style={{ fontSize: '1.5rem', color:"#fbc02d" }}></i>
                {unreadCount > 0 && (
                    <Badge value={unreadCount.toString()} severity="danger" style={{ width:"1rem" , position: 'absolute', top: "-10px", right: "-10px", padding:"2px" }} />
                )}
            </Link>
        </div>
    );
};

export default UnreadMessages;

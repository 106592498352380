import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import axios  from '../utils/axiosConfig';
const RatingModal = () => {
    const [visible, setVisible] = useState(false);
    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState('');
    const [thankYou, setThankYou] = useState(false);

    useEffect(() => {
        const lastShown = localStorage.getItem('ratingModalLastShown');
        const now = new Date().getTime();

        if (!lastShown || now - lastShown > 1000 * 60 * 60 * 24 * 60) { // 60 days
            const timer = setTimeout(() => {
                setVisible(true);
            }, 1000000); // 30 seconds

            return () => clearTimeout(timer);
            
        }

        return ()=>{
            setVisible(false);
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            const user = localStorage.getItem('user_informations');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '', // Include token if it exists
                },
            };

            const feedbackData = {
                note: rating,
                commentaire: comment,
                user: user?.id
            };

            await axios.post('/feedback', feedbackData, config);

            localStorage.setItem('ratingModalLastShown', new Date().getTime());
            setThankYou(true);
            setTimeout(() => setVisible(false), 1000); // Close modal after 3 seconds
        } catch (error) {
            console.error('Error submitting feedback:', error);
            // Handle error (e.g., show a message to the user)
        }
    };

    const handleCancel =(e)=>{
        e.preventDefault();
        localStorage.setItem('ratingModalLastShown', new Date().getTime());
        setVisible(false);

    }
    return (
        <Dialog
            visible={visible}
            style={{ width: '50vw', maxWidth: '400px' }}
            onHide={() => setVisible(false)}
            header="Notez notre site"
            footer={
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                    <Button  severity="secondary"  label="Ne plus afficher" icon="pi pi-trash" onClick={handleCancel}  />
                    <Button label="Soumettre" icon="pi pi-check" onClick={handleSubmit} disabled={!rating} />
                </div>
            }
            breakpoints={{ '960px': '75vw', '640px': '90vw' }}
        >
            {thankYou ? (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                    <h3>Merci !</h3>
                    <p>Votre avis nous aide à améliorer l'expérience utilisateur.</p>
                </div>
            ) : (
                <div>
                    <h3>Comment trouvez-vous notre plateforme ?</h3>
                    <p>Merci de prendre un moment pour évaluer votre expérience sur notre site.</p>
                    <Rating
                        value={rating}
                        onChange={(e) => setRating(e.value)}
                        stars={5}
                        cancel={false}
                        style={{ color: '#8B4513' }}
                    />
                    <InputTextarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={3}
                        placeholder="Laissez un commentaire (facultatif)"
                        style={{ marginTop: '1rem', width: '100%' }}
                    />
                </div>
            )}
        </Dialog>
    );
};

export default RatingModal;

import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import HeaderAdmin from "../partials/header-admin";
import AdminFooter from "../partials/admin-footer";
import axios, { baseURL } from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import "./infoAdd.css"; 
import { InputTextarea } from "primereact/inputtextarea";
import { useParams } from "react-router-dom";

export default function InfoSanitaireEdit() {
    const [infoSanitaire, setInfoSanitaire] = useState({
        titre: '',
        contenu: '',
        resume: '',
        image: null,
        CategoryId: '',
        nomFormationSanitaire: '',  // New field for "Nom de la formation sanitaire"
        source: ''        // New field for "Source de l'article"
    });
    const [categories, setCategories] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newCategory, setNewCategory] = useState({ nom: '', description: '' });
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const imgRef = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams(); // Récupère l'ID à partir des paramètres de l'URL
    const token = localStorage.getItem('token'); 

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('/categories', {
                    headers: {
                        'Authorization': token ? `Bearer ${token}` : ''
                    }
                });
                setCategories(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les catégories' });
            }
        };

        const fetchArticle = async () => {
            try {
                const response = await axios.get(`/informations_sanitaires/${id}`);
                setInfoSanitaire(response.data);
                setSelectedCategory(response.data?.CategoryId)
                setLoading(false);
           
            } catch (err) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite lors du chargement de l\'article.' });
                setLoading(false);
                console.log(err);
            }
        };

        fetchCategories();
        fetchArticle();
    }, [id, token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInfoSanitaire({ ...infoSanitaire, [name]: value });
    };
    
    const handleCategoryChange = (e) => {
        const selectedCategory = e.value; // Now, 'selectedCategory' will hold the ID, not an object
        setSelectedCategory(selectedCategory);
        setInfoSanitaire({ ...infoSanitaire, CategoryId: selectedCategory });
    
        // Clear or reset fields based on category selection
        const selectedCategoryObj = categories.find(cat => cat.id === selectedCategory);
    
        if (selectedCategoryObj && selectedCategoryObj.nom === "Actu santé") {
            setInfoSanitaire(prevState => ({
                ...prevState,
                nomFormationSanitaire: '' // Reset nomFormationSanitaire
            }));
        } else {
            // If it's another category, we can reset both fields
            setInfoSanitaire(prevState => ({
                ...prevState,
                nomFormationSanitaire: '',
            }));
        }
    };
    


    const handleAddCategory = async () => {
        try {
            const response = await axios.post('/categories', newCategory, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : ''
                }
            });
            setCategories([...categories, response.data]);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Catégorie ajoutée' });
            setShowCategoryModal(false);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de l\'ajout de la catégorie' });
        }
    };

    const handleImageUpload = (e) => {
        setInfoSanitaire({ ...infoSanitaire, image: e.files[0] });
        toast.current.show({ severity: 'info', summary: 'Image téléchargée', detail: `${e.files[0].name} téléchargée avec succès.` });
    };

    const handleImageRemove = () => {
        setInfoSanitaire({ ...infoSanitaire, image: null , image_url: ''});
        toast.current.show({ severity: 'warn', summary: 'Image supprimée', detail: 'L\'image a été supprimée.' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('titre', infoSanitaire.titre);
        formData.append('contenu', infoSanitaire.contenu);
        formData.append('resume', infoSanitaire.resume);
        formData.append('CategoryId', infoSanitaire.CategoryId);
        formData.append('source', infoSanitaire.source);
    
        const selectedCategoryObj = categories.find(cat => cat.id === infoSanitaire.CategoryId);
        if (selectedCategoryObj && selectedCategoryObj.nom === "Actu santé") {
            formData.append('nomFormationSanitaire', infoSanitaire.nomFormationSanitaire);
        } 
    
        if (infoSanitaire.image) {
            formData.append('image', infoSanitaire.image);
        }
    
        try {
            const response = await axios.put(`/informations_sanitaires/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token ? `Bearer ${token}` : ''
                }
            });
            setInfoSanitaire(response.data);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Information sanitaire modifiée' });
            setTimeout(() => navigate('/admin-info_sanitaire-list'), 3000);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la modification de l\'information sanitaire' });
        }
    };
    
    return (
        <>
            <div className="app-wrapper">
                <HeaderAdmin />
                <main className="app-main">
                    <div className="app-content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3 className="mb-0">Modification d'Information Sanitaire</h3>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-end">
                                        <li className="breadcrumb-item"><a href="/admin">/admin</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">info_sanitaires</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="app-content">
                        <div className="container-fluid"  style={{width:"100hv"}}>                            
                            <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '80%' }}>
                                    <div className="container">
                                        <div className="card bg-light text-dark" style={{ borderRadius: '8px',  marginTop:"7rem" }}>
                                            <div className="card-body p-4 p-md-5 text-center">
                                                {loading ? (
                                                    <p>Loading...</p>
                                                ) : (
                                                    <form onSubmit={handleSubmit} >
                                                        <div className="mb-3">
                                                            <FloatLabel>
                                                                <InputText 
                                                                    id="titre" 
                                                                    name="titre" 
                                                                    value={infoSanitaire.titre} 
                                                                    onChange={handleInputChange} 
                                                                    className="form-input" 
                                                                    required 
                                                                />
                                                                <label htmlFor="titre" className="form-label">Titre</label>
                                                            </FloatLabel>
                                                        </div>

                                                        <div className="mb-3">
                                                            <FloatLabel>
                                                                <InputTextarea 
                                                                    id="resume" 
                                                                    name="resume" 
                                                                    value={infoSanitaire.resume} 
                                                                    onChange={handleInputChange} 
                                                                    className="form-input" 
                                                                    rows={5} 
                                                                    cols={15} 
                                                                    autoResize 
                                                                />
                                                                <label htmlFor="resume" className="form-label">Résumé</label>
                                                            </FloatLabel>
                                                        </div>

                                                        <div className="mb-3">
                                                            <FloatLabel>
                                                                <Dropdown 
                                                                    id="category" 
                                                                    value={infoSanitaire.CategoryId} 
                                                                    options={categories} 
                                                                    optionLabel="nom"  
                                                                    optionValue="id"
                                                                    onChange={handleCategoryChange} 
                                                                    placeholder="Sélectionner une catégorie" 
                                                                    className="w-full md:w-14rem form-input" 
                                                                />
                                                                <label htmlFor="category" className="form-label">Catégorie</label>
                                                            </FloatLabel>
                                                        </div>

                                                        {categories.map(cat => (
                                                            cat.nom === "Actu santé" && selectedCategory === cat.id && (
                                                                <div className="mb-3">
                                                                    <FloatLabel>
                                                                        <InputText
                                                                            id="nomFormationSanitaire"
                                                                            name="nomFormationSanitaire"
                                                                            value={infoSanitaire.nomFormationSanitaire}
                                                                            onChange={handleInputChange}
                                                                            className="form-input"
                                                                            required
                                                                        />
                                                                        <label htmlFor="nomFormationSanitaire" className="form-label">Nom de la formation sanitaire</label>
                                                                    </FloatLabel>
                                                                </div>
                                                            )
                                                        ))}

                                                        
                                                        
                                                                <div className="mb-3" style={{width:'10rem'}}>
                                                                    <FloatLabel>
                                                                        <InputText
                                                                            id="source"
                                                                            name="source"
                                                                            value={infoSanitaire.source}
                                                                            onChange={handleInputChange}
                                                                            className="form-input"
                                                                            required
                                                                        />
                                                                        <label htmlFor="source" className="form-label">Source de l'informations sanitaires</label>
                                                                    </FloatLabel>
                                                                </div>
                                                        

                                                        {/* Carte pour afficher l'image */}
                                                        <div className="mb-3">
                                                        {infoSanitaire.image || infoSanitaire.image_url ? (
                                                            <Card 
                                                                title="Image"
                                                                className="image-card"
                                                                style={{ position: 'relative', width: '100%' }}
                                                            >
                                                                <img src={infoSanitaire.image ? URL.createObjectURL(infoSanitaire.image) : baseURL + infoSanitaire.image_url} 
                                                                    alt="uploaded" 
                                                                    style={{ width: '100%' }} />
                                                                <div style={{ position: 'absolute', bottom: 0, left: 0, display: 'flex', justifyContent: 'space-between', width: '100%', background: 'rgba(0, 0, 0, 0.6)' }}>
                                                                    <Button icon="pi pi-trash" className="p-button-danger" rounded onClick={handleImageRemove} />
                                                                    <input type="file" accept="image/*" style={{display:"none"}}  ref={imgRef} onChange={(e)=>setInfoSanitaire({...infoSanitaire, image: e.target.files[0]})} />
                                                                    <Button type="button" icon="pi pi-pencil" className="p-button-warning" rounded onClick={()=>imgRef.current.click()}/>
                                                                </div>
                                                            </Card>
                                                        ) : (
                                                            <FileUpload 
                                                                mode="basic" 
                                                                name="image" 
                                                                accept="image/*" 
                                                                customUpload 
                                                                uploadHandler={handleImageUpload} 
                                                                className="form-input" 
                                                                auto 
                                                                chooseLabel="Sélectionner une image"
                                                            />
                                                        )}
                                                    </div>

                                                        <div className="mb-3">
                                                            <label htmlFor="contenu" className="form-label">Contenu de l'information sanitaire:</label>
                                                            <Editor 
                                                                value={infoSanitaire.contenu} 
                                                                onTextChange={(e) => setInfoSanitaire({ ...infoSanitaire, ['contenu']: e.htmlValue })} 
                                                                style={{ height: '320px' }} 
                                                            />
                                                        </div>

                                                        <Button label="Modifier Information" icon="pi pi-check" type="submit" className="submit-button" />
                                                    </form>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                        </div>
                    </div>
                </main>
                <AdminFooter />
            </div>

            <Toast ref={toast} />

            <Dialog 
                header="Ajouter une Catégorie" 
                visible={showCategoryModal} 
                style={{ width: '50vw' }} 
                onHide={() => setShowCategoryModal(false)}
                footer={
                    <div>
                        <Button label="Annuler" icon="pi pi-times" onClick={() => setShowCategoryModal(false)} className="p-button-text" />
                        <Button label="Ajouter" icon="pi pi-check" onClick={handleAddCategory} />
                    </div>
                }
            >
                <form>
                    <div className="mb-3">
                        <FloatLabel>
                            <InputText 
                                id="nom" 
                                name="nom" 
                                value={newCategory.nom} 
                                onChange={(e) => setNewCategory({ ...newCategory, ['nom']: e.target.value })} 
                                className="form-input" 
                            />
                            <label htmlFor="nom" className="form-label">Nom</label>
                        </FloatLabel>
                    </div>

                    <div className="mb-3">
                        <FloatLabel>
                            <InputTextarea 
                                id="description" 
                                name="description" 
                                value={newCategory.description} 
                                onChange={(e) => setNewCategory({ ...newCategory, ['description']: e.target.value })} 
                                className="form-input" 
                                rows={3} 
                                cols={30} 
                                autoResize 
                            />
                            <label htmlFor="description" className="form-label">Description</label>
                        </FloatLabel>
                    </div>
                </form>
            </Dialog>
        </>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { useNavigate } from 'react-router-dom';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const QuizzesPage = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuiz, setSelectedQuiz] = useState(null); // Pour stocker le quiz à modifier
    const [updateDialogVisible, setUpdateDialogVisible] = useState(false); // Contrôle la visibilité du formulaire
    const [updatedName, setUpdatedName] = useState(''); // Gère les modifications
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuizzes = async () => {
            try {
                const response = await axiosInstance.get('/quizz', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setQuizzes(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement des quizz', life: 3000 });
            } finally {
                setLoading(false);
            }
        };

        fetchQuizzes();
    }, []);

    const handleActionClick = async (action, quiz) => {
        if (action === 'edit') {
            setSelectedQuiz(quiz);
            setUpdatedName(quiz.name); // Pré-remplir le formulaire avec le nom existant
            setUpdateDialogVisible(true);
        } else if (action === 'addgroup') {
            navigate(`/admin-questionGroupAdd`);
        } else if (action === 'delete') {
            confirmDialog({
                message: 'Êtes-vous sûr de vouloir supprimer ce quizz?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        await axiosInstance.delete(`/quizz/${quiz.id}`);
                        setQuizzes(quizzes.filter(q => q.id !== quiz.id));
                        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Quizz supprimé', life: 3000 });
                    } catch (error) {
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la suppression du quizz', life: 3000 });
                    }
                },
                reject: () => {
                    toast.current.show({ severity: 'info', summary: 'Annulé', detail: 'Suppression annulée', life: 3000 });
                }
            });
        }
    };

    const updateQuiz = async () => {
        try {
            await axiosInstance.put(`/quizz/${selectedQuiz.id}`, { name: updatedName },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setQuizzes(quizzes.map(q => (q.id === selectedQuiz.id ? { ...q, name: updatedName } : q)));
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Quizz mis à jour', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la mise à jour', life: 3000 });
        } finally {
            setUpdateDialogVisible(false);
            setSelectedQuiz(null);
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <Dropdown
                options={[
                    { label: 'Modifier', value: 'edit' },
                    { label: 'Supprimer', value: 'delete' },
                    { label: 'Ajouter un groupe ', value: 'addgroup' }
                ]}
                onChange={(e) => handleActionClick(e.value, rowData)}
                placeholder="Actions"
                className="p-dropdown"
            />
        );
    };

    return (
        <>
            <div className="app-wrapper">
                <HeaderAdmin />
                <main className="app-main">
                    <div className="app-content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3 className="mb-0">Les Quizz</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-content">
                        <div className="container-fluid">
                            <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '1200px' }}>
                                <div className="container">
                                    <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
                                        <div className="card-body p-4 p-md-5 text-center">
                                            <h1 className="h2 fw-bold mb-4">Quizz</h1>
                                            <DataTable value={quizzes} loading={loading} paginator rows={10} responsiveLayout="scroll">
                                                <Column field="name" header="Nom" />
                                                <Column header="Actions" body={actionTemplate} />
                                            </DataTable>
                                            <Toast ref={toast} />
                                            <ConfirmDialog />
                                            <Dialog
                                                visible={updateDialogVisible}
                                                header="Modifier le Quizz"
                                                modal
                                                style={{ width: '450px' }}
                                                onHide={() => setUpdateDialogVisible(false)}
                                            >
                                                <div className="p-fluid">
                                                    <div className="p-field" style={{ marginBottom: '20px' }}>
                                                        <label htmlFor="name" style={{ display: 'block', marginBottom: '10px', fontWeight: 'bold' }}>Nom</label>
                                                        <InputText
                                                            id="name"
                                                            value={updatedName}
                                                            onChange={(e) => setUpdatedName(e.target.value)}
                                                            className="p-inputtext"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                    <div className="p-field" style={{ textAlign: 'right' }}>
                                                        <Button
                                                            label="Annuler"
                                                            icon="pi pi-times"
                                                            className="p-button-text p-button-danger"
                                                            onClick={() => setUpdateDialogVisible(false)}
                                                            style={{ marginRight: '10px' }}
                                                        />
                                                        <Button
                                                            label="Enregistrer"
                                                            icon="pi pi-check"
                                                            className="p-button"
                                                            onClick={updateQuiz}
                                                        />
                                                    </div>
                                                </div>
                                            </Dialog>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
                <AdminFooter />
            </div>
        </>
    );
};

export default QuizzesPage;


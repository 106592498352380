import React, { useEffect, useState } from 'react';
import { Paginator } from 'primereact/paginator';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import axios from '../utils/axiosConfig';
import './faq.css';


const Faq = () => {
  
  const [first, setFirst] = useState(0);
  const [faqs, setFaqs] = useState([]);
  const [rows, setRows] = useState(10); // Nombre d'éléments par page
  const [loading, setLoading] = useState(true); // Ajout de l'état loading

  const toastRef = React.createRef();
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get('/faqs');
        setFaqs(response.data);
        // toastRef.current.show({ severity: 'success', summary: 'Succès', detail: 'FAQs récupérées avec succès!', life: 0.1 });
      } catch (error) {
        console.error('Erreur lors de la récupération des FAQs:', error);
      //   toastRef.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des FAQs.', life: 3000 });
      // } finally {
        setLoading(false); // Réinitialisation de l'état loading
      }
    };

    fetchFAQs();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedFaqs = faqs.slice(first, first + rows);

  return (
    <>
      <NavBar />
      <div id="main" style={{ height: '100%' }}>
        <div className="pt-0 pb-5" style={{marginTop:'12rem'}}>
          {/* <Toast ref={toastRef} /> */}
          <div className="w-100" style={{ margin: '0 auto' }}>
            <img
              src="/data/applications/pictures/faq.png"
              className="lazyload img-fluid w-100"
              alt=""
              title=""
            />
          </div>
        </div>
        <div className="container pt-5 pb-5">
          <div className={isMobile ? "w-100":"w-100"} style={{ margin: '0 auto' }}>
            <div className="accordion" id="accordion-387983">
              {paginatedFaqs.map((faq) => (
                <div className="accordion-item" style={{ border: 'none' }} key={faq.id}>
                  <h2 className="accordion-header" id={`heading-${faq.id}`}>
                    <button
                      className="accordion-button bg-primary text-light fw-bolder fs-6 collapsed"
                      type="button"
                      data-mdb-toggle="collapse"
                      data-mdb-target={`#collapse-${faq.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse-${faq.id}`}
                      style={{ opacity: 0.9, boxShadow: 'none' }}
                    >
                      <span className="fw-bold me-2 d-inline-block"></span>
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse-${faq.id}`}
                    className="accordion-collapse collapse bg-primary text-light"
                    style={{ boxShadow: 'none' }}
                    aria-labelledby={`heading-${faq.id}`}
                    data-mdb-parent="#accordion-387983"
                  >
                    <div className="accordion-body">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Paginator
              first={first}
              rows={rows}
              totalRecords={faqs.length}
              rowsPerPageOptions={[30,50]}
              onPageChange={onPageChange}
              className="mt-4"
            />
          </div>
        </div>
        <Footer />
      </div>
     
    </>
  );
};

export default Faq;
